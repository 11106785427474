import React, {Component} from 'react'
import { Redirect } from "react-router-dom";
import {Link} from "react-router-dom";
import Cookies from 'js-cookie';

class Navbar extends Component {
  
constructor(){
  super();
  

  this.state = {
    redirect:null,   
    isLoading:false
  };
}

componentDidMount(){
  const cookieExists = Cookies.get('meatco_cookie');

  if(!cookieExists){
    this.setState({redirect: "/login"});
  }
}



logout(){ 
  Cookies.remove('meatco_cookie');
  Cookies.remove('meatco_user');
  this.setState({redirect: "/login"});
}

loggedIn(){

}

 render(){
  if(this.state.redirect){
    return <Redirect to={this.state.redirect} />
  }
	 return(
     <div className="container-fluid">
       <div className="navbar-wrapper">
         <Link className='navbar-brand' to="/">
           <img src="assets/img/logo-cropped.svg" alt=""></img>
         </Link>
       </div>
       <button className="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
         <span className="sr-only">Toggle navigation</span>
         <span className="navbar-toggler-icon icon-bar"></span>
         <span className="navbar-toggler-icon icon-bar"></span>
         <span className="navbar-toggler-icon icon-bar"></span>
       </button>
       <div className="collapse navbar-collapse justify-content-end">
         <ul className="navbar-nav">
             <li className="nav-item">
                 <Link className='nav-link profile-navlink' to="/profile">Profile</Link>
               </li>
           <li className="nav-item">
           <button className="btn btn-danger btn-fill login-button" onClick="logout()" onClick={() => this.logout()}>Logout</button>
             {/* <a className="btn btn-danger btn-fill login-button" onClick="this.logout()">
               Logout
             </a> */}
           </li>
         </ul>
       </div>
     </div>
	 )
 }
}

export default Navbar
