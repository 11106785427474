import React, {Component} from 'react'
import axios from "axios";
import { Redirect } from "react-router-dom";
import Cookies from 'js-cookie';


import Navbar from '../components/Navbar';
import Shelves from '../components/dashboard/Shelves';
import Dropzone from '../components/dashboard/Dropzone';
import PalletModal from '../components/Modals/PalletModal';
import ExpiryModal from '../components/Modals/expiryModal';

import * as appConst from '../functions/Constants';
const apiUrl = appConst.API_URL;

// Navigation bar
class CreateIndex extends Component{
// Class constructor 
constructor(props){


	super(props);
    this.state = {
      error: null,
      isLoaded: false,
      alreadyRendered : 'false',
      pallets_url : '',
      shelves_url : '',
      coldrooms_url : '',
      factories_url :apiUrl+'/factories/list',
      api_key : Cookies.get('meatco_cookie') ,
      expiryModalOpen: "",
      checkModal : "",
      shelves: [],
      pallets: [],
      expiringPallets: [],
      palletModal : [],
      factories : [],
      ColdroomList: [],
      currentFactory : '',
      currentColdroom: '',

      allPalletData : [],

      currentFactoryName : '',
      currentColdroomName: '',
      currentFactoryId : '',
      firstColdroomId: '',

    };
  }


// Declare autorefresh ID
intervalID;

// HTTP request to get all pallets
get_pallets = () => {

  axios.get(this.state.pallets_url, 
  {
    headers: {
      Authorization: this.state.api_key
    }
  })
  .then(pallets_res => {

    if(pallets_res.data.status == "error" ){
      this.setState({ 
        pallets:[] 
      });
    }else{

      this.setState({ 
        pallets :pallets_res.data.pallets,
        expiringPallets : pallets_res.data.expiry,
        allPalletData : pallets_res.data,

        currentFactoryName : pallets_res.data.factory[0]['factory_name'],
        currentColdroomName:  pallets_res.data.coldroom[0]['coldroom_name'],
        currentFactoryId : pallets_res.data.factory[0]['factory_id'],
        firstColdroomId : pallets_res.data.coldroom[0]['coldroom_id'],
      });

      if(pallets_res.data.expiry.length === 0){
        this.setState({
          expiryModalOpen : " ",
        });
      }else{

        if(this.state.checkModal === "open"){
          this.setState({
            checkModal : " ",
            expiryModalOpen : "show",
          });
        }

      }

    }

  })
  .catch(pallets_err => {
    this.setState({ 
      error: "There was a problem with getting Pallets data from the API" 
    })


  });
}

// HTTP request to get all the shelves
get_shelves = () => {
  axios.get(this.state.shelves_url, 
	{
		headers: {
			Authorization: this.state.api_key
		}
	})
  .then(res => {

      if(res.data.status == "error" ){

        this.setState({ 
          error:"This coldroom has no shelves.", 
        });

      }else{
        this.setState({ 
          shelves : res.data,
          checkModal : "open"
        });
        
      }  

  })
  .catch(err => {
    this.setState({ 
      error: "There was a problem with getting Shelves from the API" 
    })
  });
}

// HTTP request to get all the Coldrooms
get_coldrooms = () => {
  axios.get(this.state.coldrooms_url, 
	{
		headers: {
			Authorization: this.state.api_key
		}
	})
  .then(res => {

    if(res.data.status == "error" ){
      this.setState({ 
        error:"No coldrooms found.", 
      });
    }else{
      this.setState({ 
        ColdroomList : res.data,
      });
    }
  

  })
  .catch(err => {
    this.setState({ 
      error: "There was a problem with getting Coldrooms data from API" 
    })
  });
}

// HTTP request to get all the Factories
get_factories = () => {
  axios.get(this.state.factories_url, 
	{
		headers: {
			Authorization: this.state.api_key
		}
	})
  .then(res => {
    
    this.setState({ 
      factories : res.data,
      expiringPallets: [],
    });

    this.get_coldrooms();
    this.get_shelves();
    this.get_pallets();

  })
  .catch(err => {
    this.setState({ 
      error: "There was a problem with getting factorories data from API" 
    })
  });
}


// Onlcik event to pass pallet info to modal
palletModalData = (palletId) => {
  
  var pallets = this.state.pallets;

  pallets.forEach(
  function getPallet(item, index) {

    if(item.id === palletId){
        this.setState({
          palletModal : item
        });
    }
  }, this);
  
}


// Onclick to make HTTP request after changing top dropdowns
changeReqguestUrl = (factory_id, coldroom_id) => {


   // Search if there are any queries on the URL and assion query values
   var params = new URLSearchParams(this.props.location.search);
   var query_factory_id = params.get('factory_id');
   var query_coldroom_id = params.get('coldroom_id')



  if(factory_id === ""){
      this.setState({
        error : "You must select a factory.",
       });
  }else if(coldroom_id === ""){

    this.setState({
      error : "There are coldrooms in this factory.",
     });

  }else{

      var currURL= window.location.href;
      var afterDomain= currURL.substring(currURL.lastIndexOf('/') + 1);
      var beforeQueryString= afterDomain.split("?")[0];

      var myNewURL = beforeQueryString;
      window.history.pushState("object or string", "Title", "/" + myNewURL );
    
 
      // Check if there are any queries in the URL
      if(afterDomain !== "" && afterDomain !== "#" &&  afterDomain !== null){
        this.setState({ 
            error : null,
            pallets_url : apiUrl+'/pallets/list-pallets/'+query_factory_id+'/'+query_coldroom_id,
            shelves_url : apiUrl+'/shelves/list/'+query_coldroom_id,
            coldrooms_url : apiUrl+'/coldroom/from-factory/'+query_factory_id,   
        });

      }else{
          this.setState({ 
            error : null,
            pallets_url : apiUrl+'/pallets/list-pallets/'+factory_id+'/'+coldroom_id,
            shelves_url : apiUrl+'/shelves/list/'+coldroom_id,
            coldrooms_url : apiUrl+'/coldroom/from-factory/'+factory_id,
          });  

      };


    this.get_factories();

  }
}





componentDidMount() {
  this.intervalID = setInterval(this.get_pallets.bind(this), 5000); 
}

componentWillMount(){
  
  this.changeReqguestUrl(3, 5);
}

componentWillUnmount() {
  clearInterval(this.intervalID);
}

closeModal = () =>{

  this.setState({
    expiryModalOpen : ""
  });

}


render(){
  // First check for login
  const cookieExists = Cookies.get('meatco_cookie');
  const userExist = Cookies.get('meatco_user');
  if(!cookieExists || !userExist){
    return <Redirect to="/login" />
  }else{
  
  // Check for errors before rendering Dropzone
  var dropzone_html;
  if(this.state.error != null){
    dropzone_html = (
      <div>
        
      </div>
    );
  }else{
       
    dropzone_html = (  
      <div id="dropZone" className="container dropzone-container">
                <Dropzone 
                  shelves={this.state.shelves} 
                  pallets={this.state.pallets}
                  pallet_ID={this.palletModalData.bind(this)}
                  error = {this.state.error}
                />
              </div>
    );
  }

  return ( 
      <div>
      <div className="wrapper ">
        <div className="main-panel">

          <nav id="top-navbar" className="navbar navbar-expand-lg navbar-default navbar-absolute fixed-top ">
            <Navbar/>
          </nav>

          <div className="content">
          
            <div className="container" id="shelves-row">
              <Shelves 
                shelves= {this.state.shelves} 
                pallets= {this.state.pallets}
                pallet_ID = {this.palletModalData.bind(this)}
                factories = {this.state.factories}
                ColdroomList = {this.state.ColdroomList}
                requestUrl = {this.changeReqguestUrl.bind(this)}

                currentFactory = {this.state.currentFactoryName}
                currentColdroom = {this.state.currentColdroomName}
                currentFactoryId = {this.state.currentFactoryId}
                currentColdroomId = {this.state.currentColdroomId}

                error = {this.state.error}
              />
            </div>

            {dropzone_html}

          </div>

          
        </div>
      </div>

    <div className="modal fade" id="paletteModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <PalletModal pallet={this.state.palletModal}/>
    </div>


    <div className={"modal fade "+this.state.expiryModalOpen} id="reminderpalettes" tabIndex="-1" role="dialog" aria-labelledby="reminderpalettesLabel" aria-hidden="true">
      <ExpiryModal 
        expiringPallets={this.state.expiringPallets}
        currentFactoryName = {this.state.currentFactoryName}
        currentColdroomName = {this.state.currentColdroomName}
        closeModal={this.closeModal.bind(this)}
      />
    </div>

    </div>
    )
  }
}
}

export default CreateIndex;
