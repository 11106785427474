import React, {Component} from 'react';
import axios from "axios";
import { Redirect } from "react-router-dom";
import Cookies from 'js-cookie';

import * as appConst from '../../functions/Constants';
const apiUrl = appConst.API_URL;

class UpdatePassword extends Component {

// Class constructor 
constructor(props){

	super(props);
    this.state = {
      error: null,
      updateMessage : '',
      isLoaded: false,  
      currentPass : '',
	  newPass : '',
	  newPass_2 : '',
	  emailAddress : '',
	  
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

// HTTP request to update current user
updateUserPassword = () => {
	const data = {
		'user_password': this.state.currentPass,
		'new_password': this.state.newPass,
	}
  
	const headers = {
	  Authorization: Cookies.get('meatco_cookie'),
	  'Content-Type': 'application/json',
	}
		
	axios.put(apiUrl+'/user/reset-pass/'+this.props.user_id, data, {
		headers: headers
	})
	.then(res => {
	  
	  this.setState({ 
		updateMessage : res.data.msg,
	  });

	  if(res.data.status === "error"){
		this.props.openAlertModal(this.state.updateMessage, " ", "Error!");
	  }else{
		this.props.openAlertModal(this.state.updateMessage, " ", "Success!");
	  }

	  

	})
	.catch(err => {
	  this.setState({ 
		updateMessage: "There was a technical problem while updating your profile information." 
	  })

	  this.props.openAlertModal(this.state.updateMessage, " ", "Error!");
  
	});
}
  
handleChange(event) {
this.setState({
	[event.target.name]: event.target.value
});
}

handleSubmit(event) {
	event.preventDefault();


	if(this.state.newPass === "" || this.state.newPass_2 === "" || this.state.currentPass === ""){
		this.props.openAlertModal("You must fill in all the fields.", " ", "Error!");
	}else if(this.state.newPass !== this.state.newPass_2){
		this.props.openAlertModal("Your passwords do not match.", " ", "Error!");
	}else{
		this.updateUserPassword();
	}	
}
  
  

 render(){
	 return(
     
		<div className="tab-pane" id="password" role="tabpanel" aria-labelledby="password-tab">
		<div className="row">
		  <div className="col-md-12">
			  <h3>Update password</h3>
		  </div>
		</div>
			<div className="row">
				<div className="col-md-12">
				<form onSubmit={this.handleSubmit}>
					<div className="form-group row px-0 bmd-form-group">
					  <div className="col-md-3 px-0"><label htmlFor="currentPass" className="col-form-label text-md-right">Current password</label></div>
						<div className="col-md-9 pl-5">
							<input type="password" id="currentPass" className="form-control" name="currentPass" placeholder="Current password" onChange={this.handleChange} required="" autoFocus=""/>
						</div>
					</div>
					<div className="form-group row px-0 bmd-form-group">
						<div className="col-md-3 px-0"><label htmlFor="newPass" className="col-form-label text-md-right">New password</label></div>
						  <div className="col-md-9 pl-5">
							  <input type="password" id="newPass" className="form-control" name="newPass" placeholder="New password" onChange={this.handleChange} required="" autoFocus=""/>
						  </div>
					  </div>
					  <div className="form-group row px-0 bmd-form-group">
						  <div className="col-md-3 px-0"><label htmlFor="newPass_2" className="col-form-label text-md-right">Confirm password</label></div>
							<div className="col-md-9 pl-5">
								<input type="password" id="newPass_2" className="form-control" name="newPass_2" placeholder="Confirm new password" onChange={this.handleChange} required="" autoFocus=""/>
							</div>
						</div>

						<div className="form-group row px-0 text-right">
						<div className="col-md-12">
							<button type="submit" className="btn btn-default pull-right">Update</button>
						</div>
						</div>
				</form>
				</div>
			</div>
	  </div>
      
	 )
 }
}

export default UpdatePassword
