import React, {Component} from 'react'
import {Link } from "react-router-dom";
import axios from 'axios';
import Cookies from 'js-cookie';


import * as appConst from '../../functions/Constants';
const apiUrl = appConst.API_URL;


class ForgotForm extends Component {

  constructor(props){
    super(props);
    this.state = {
      updateMessage : '',
      email_address : '',
      reset_status : '',
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

forgotPassRequest = () =>{

  const headers = {
    Authorization: Cookies.get('meatco_cookie'),
      'Content-Type': 'application/json',
    }

    axios.post(apiUrl+'/user/create-reset-url/'+this.state.email_address ,{
      headers: headers
    })
    .then(res => {
      
      if(res.data.status === "error"){
        
        this.setState({ 
          updateMessage : res.data.msg,
          reset_status: '',
        });

      }else{

        this.setState({
          reset_status: 'success',
          updateMessage : '',
        });

      }
      
    })
    .catch(err => {
      this.setState({ 
        updateMessage: "There was a technical problem while sending reset link." 
      })

      console.log(err);

    });


}

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    if(this.state.new_password === ""){
      this.setState({
        updateMessage :"All fields must be filled in.",
      });
    }else{
      this.forgotPassRequest();
    }

    
  }

 render(){
  if(this.state.reset_status === 'success'){
    return(
      <div>
        <div className="form-group row px-0">
          <h3>Success!</h3>
          <p>A reset link has been emailed to <b>{this.state.email_address}</b> for reseting your password.</p>
        </div>
        <div className="form-group row px-0">
          <div className="col-md-12 px-0">
              <Link to="/login" className="btn btn-default">
                Click to login
              </Link>
          </div>
        </div>
      </div>
    )
  }else{
	 return(
      <div>
          <h3>Forgot your password?</h3>
          <p>Enter your email address below and a link will be emailed to you to reset your password.</p>
          
          { this.state.updateMessage && <div className="reset-error alert alert-danger alert-dismissible fade show"> { this.state.updateMessage } </div> }
            <form onSubmit={this.handleSubmit}>
                <div className="form-group row px-0">
                  <div className="col-md-3 px-0"><label for="email_address" className="col-form-label text-md-right">Email</label></div>
                    <div className="col-md-9 pr-0">
                        <input type="email" id="email_address" className="form-control" name="email_address" placeholder="Email address" onChange={this.handleChange} value={this.state.email_address} required autofocus />
                    </div>
                </div>

                <div className="form-group row px-0">
                  <div className="col-md-12 px-0">
                      
                      <button type="submit" className="btn btn-default"> Reset password</button>

                      <Link to="/login" className="btn btn-link forgot-password pull-right pr-0">
                        Back to login
                      </Link>
                  </div>

                </div>
          </form>
      </div>
    )
   }
 }
}

export default ForgotForm



