import React, {Component} from 'react'
import axios from "axios";
import Cookies from 'js-cookie';

import * as appConst from '../../functions/Constants';
const apiUrl = appConst.API_URL;

class Users extends Component {

// Class constructor 
constructor(props){

	super(props);
    this.state = {
      error: null,
      actionMessage : '',
      isLoaded: false,
	  usersDetails : [],

	  addUserModalOpen :'',
	  alertModalOpen :'',
		
	  edit_user_id : '',
	  modal_title : '',
	  ButtonText : '',
	  submitAction : '',

	  get_newPass : '',
	  get_newPass_2 : '',
	  get_userEmail : '',
	  get_firstName : '',
	  get_lastName : '',
	  get_profType : '',
	  confirmDeleteModal : '',

	  selectVal : 'select...',
	  selectText : '',

    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

}


// HTTP request to get All users
getUsersDetails = () => {
	axios.get(apiUrl+'/user/list', 
	  {
		  headers: {
			  Authorization: Cookies.get('meatco_cookie'),
			  'Content-Type': 'application/json'
		  }
	  })
	.then(res => {
	  this.setState({ 
		usersDetails : res.data,
	  });
   
	})
	.catch(err => {
	  this.setState({ 
		error: "There was a technical problem while fetching users information.",
		actionMessage : "There was a technical problem while fetching users information."
	  });
	  this.props.openAlertModal(this.state.actionMessage, "", "Error");
	});
}

// HTTP request to update user
updateUserDetails = (user_id) => {

	const data = {
		'first_name': this.state.get_firstName,
		'last_name': this.state.get_lastName,
		'user_email': this.state.get_userEmail,
		'user_type': this.state.get_profType,
		'user_password': this.state.get_newPass,
	}
  
	const headers = {
	  Authorization: Cookies.get('meatco_cookie'),
	  'Content-Type': 'application/json',
	}
  
	axios.put(apiUrl+'/user/update/'+this.state.get_userEmail,data,{
	  headers: headers
	})
	.then(res => {
	  
	  if(res.data.status === "error"){
		this.setState({ 
			actionMessage : res.data.msg,
		});
		this.props.openAlertModal(this.state.actionMessage, " ", "Error!");
	  }else{
		this.setState({ 
			actionMessage : res.data.msg,
			addUserModalOpen : " ",
			confirmDeleteModal : '',
		});

		this.getUsersDetails(); 

		this.props.openAlertModal(this.state.actionMessage, " ", "Success!");
	  }


	  
	})
	.catch(err => {
	  this.setState({ 
		actionMessage: "There was a technical problem while updating user information.",
		addUserModalOpen : '' 
	  })
  
	  this.props.openAlertModal(this.state.actionMessage, "", "Error");
  
	});
  
}

// HTTP request to get user
getSingleUser = (user_id) => {
	axios.get(apiUrl+'/user/get/'+user_id, 
	  {
		  headers: {
			  Authorization: Cookies.get('meatco_cookie')
		  }
	  })
	.then(res => {
	  
	  this.setState({ 
		get_firstName : res.data[0].first_name,
		get_lastName : res.data[0].last_name,
		get_userEmail : res.data[0].user_email,
		get_profType : res.data[0].user_type,
		selectVal : res.data[0].user_type,
	  	selectText : res.data[0].user_type,
	  });


	})
	.catch(err => {
	  this.setState({ 
		error: "There was a technical problem while fetching the user.",
		actionMessage : "There was a technical problem while fetching the user.",
		addUserModalOpen : '' 
	  });

	  this.props.openAlertModal(this.state.actionMessage, "", "Error");

	});
}


// HTTP request to add new user
CreateNewUser = () => {

	const data = {
		'first_name': this.state.get_firstName,
		'last_name': this.state.get_lastName,
		'user_email': this.state.get_userEmail,
		'user_type': this.state.get_profType,
		'user_password': this.state.get_newPass,
	}
  
	const headers = {
	  Authorization : Cookies.get('meatco_cookie'),
	  'Content-Type': 'application/json',
	  Accept : 'application/json'
	}
  
	axios.post(apiUrl+'/user/create',data,{
	  headers: headers
	})
	.then(res => {
	  
	  
	  this.getUsersDetails();

	  if(res.data.status === "error"){
		this.setState({ 
			actionMessage : res.data.msg,
		});
		this.props.openAlertModal(this.state.actionMessage, " ", "Error!");
	  }else{
		this.setState({ 
			actionMessage : res.data.msg,
			addUserModalOpen : " ",
		});

		this.props.openAlertModal(this.state.actionMessage, " ", "Success!");
	  }
	  
	})
	.catch(err => {
	  this.setState({ 
		actionMessage: "There was a technical problem while creating the user.",
		addUserModalOpen : '' 
	  });
	  this.props.openAlertModal(this.state.actionMessage, " ", "Success!");
	});
}

// HTTP request to delete user
deleteUser = (user_id) => {
	axios.delete(apiUrl+'/user/delete/'+user_id, 
	  {
		  headers: {
			  Authorization: Cookies.get('meatco_cookie')
		  }
	  })
	.then(res => {
	  
	  this.setState({ 
		actionMessage : res.data.msg,
		addUserModalOpen : "",
	  });

	  if(res.data.status === "error"){
		this.setState({ 
			actionMessage : res.data.msg,
		});
		this.props.openAlertModal(this.state.actionMessage, " ", "Error!");
	  }else{
		this.setState({ 
			actionMessage : res.data.msg,
			addUserModalOpen : " ",
			confirmDeleteModal : '',
		});

		this.getUsersDetails(); 

		this.props.openAlertModal(this.state.actionMessage, " ", "Success!");
	  }

	})
	.catch(err => {
	  this.setState({ 
		error: "There was a technical problem while deleting the user.",
		actionMessage : "There was a technical problem while deleting the user.",
		addUserModalOpen : '' 
	  });

	  this.props.openAlertModal(this.state.actionMessage, " ", "Success!");

	});
}



handleChange(event) {
  this.setState({
    [event.target.name]: event.target.value
  });
}

handleSubmit(event) {
  event.preventDefault();	

  if(this.state.submitAction === "add"){


	if( this.state.get_profType === "" || 
		this.state.get_firstName === "" || 
		this.state.get_lastName === "" || 
		this.state.get_newPass === "" || 
		this.state.get_newPass_2 === "")
	{
		this.props.openAlertModal("All fields must be filled in.", " ", "Error!");

	}else if(this.state.get_newPass !== this.state.get_newPass_2){
		this.props.openAlertModal("The passwords entered do not match.", " ", "Error!");
	}else{
		this.CreateNewUser();
	}

	
  }

  if(this.state.submitAction === "edit"){
	if( this.state.get_profType === "" || 
		this.state.get_firstName === "" || 
		this.state.get_lastName === "" )
	{
		this.props.openAlertModal("All fields must be filled in.", " ", "Error!");

	}else if(this.state.get_newPass !== this.state.get_newPass_2){
		this.props.openAlertModal("The passwords entered do not match.", " ", "Error!");
	}else{

		this.updateUserDetails();
	}
  }
  
}


closeModal = () =>{
	this.setState({
		addUserModalOpen : "",
		modal_title : '',
		edit_user_id : '',
		ButtonText : '',
		get_newPass : '',
		get_newPass_2 : '',
		get_newPass : '',
		get_firstName : '',
		get_lastName : '',
		get_profType : '',
		submitAction : '',
		confirmDeleteModal : '',
	}); 
}

closeDelete = () =>{
	this.setState({
		confirmDeleteModal : '',
	}); 
}

openModal = (title) =>{
	this.setState({
		addUserModalOpen : "show",
		modal_title : title,
		ButtonText : 'Add user',
		get_newPass : '',
		get_newPass_2 : '',
		get_userEmail : '',
		get_firstName : '',
		get_lastName : '',
		get_profType : '',
		submitAction : 'add',
		selectVal : '',
		selectText : 'select...',
	});
}

editUserModal = (title, user_id) =>{
	this.setState({
		addUserModalOpen : "show",
		edit_user_id : user_id,
		modal_title : title,
		ButtonText : 'Update user',
		submitAction : 'edit',
		get_newPass : '',
	  	get_newPass_2 : '',
	});

	this.getSingleUser(user_id);
}


deleteUserModal = () =>{
	this.deleteUser(this.state.edit_user_id);
	this.getUsersDetails();  
}

deleteBtn= () =>{
	this.setState({
		confirmDeleteModal : 'show',
	});
}

componentDidMount() {
    this.getUsersDetails();  
}


newUserModalHTML = () =>{
	return(        
        <div className={this.state.submitAction+" modal fade "+this.state.addUserModalOpen} id="addNewUserModal" tabIndex="-1" role="dialog" aria-labelledby="addNewUser" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered animated fadeIn" role="document">
          <div className="modal-content">
                <div className="modal-header">
                    <span onClick={() => {this.closeModal()} } data-dismiss="modal" className="ai-close-icon"><img src="assets/img/close-icon.svg" alt="close"/></span>
                    <h3 className="modal-title">{this.state.modal_title}</h3>
                </div>
                <div className="modal-body">
					
					<form onSubmit={this.handleSubmit}>
						<div className="row">
							<div className="col-md-6">
								
								<div className="form-group row px-0">
									<div className="col-md-3 px-0">
									<label htmlFor="get_firstName" className="col-form-label text-md-right">First name</label></div>
									<div className="col-md-9 pr-0">
										<input type="text" id="get_firstName" className="form-control" name="get_firstName" placeholder=" " value={this.state.get_firstName || ''} required onChange={this.handleChange} />
									</div>
								</div>

								<div className="form-group row px-0">
									<div className="col-md-3 px-0">
										<label htmlFor="get_userEmail" className="col-form-label text-md-right">Email</label>
									</div>
									<div className="col-md-9 pr-0">
										<input type="email" id="get_userEmail" className="form-control" name="get_userEmail" placeholder=" " value={this.state.get_userEmail || ''} required onChange={this.handleChange} />
									</div>
								</div>
								
							</div>
							<div className="col-md-6">

								<div className="form-group row px-0">
									<div className="col-md-3 px-0">
									<label htmlFor="get_lastName" className="col-form-label text-md-right">Last name</label></div>
									<div className="col-md-9 pr-0">
										<input type="text" id="get_lastName" className="form-control" name="get_lastName" placeholder=" " value={this.state.get_lastName || ''} required onChange={this.handleChange}/>
									</div>
								</div>

								<div className="form-group row px-0">
									<div className="col-md-3 px-0"><label htmlFor="get_profType" className="col-form-label text-md-right">Profile type</label></div>
									<div className="col-md-9 pr-0">
											<div className="input-group mb-3">
												<select className="form-control custom-select" id="get_profType" name="get_profType" required onChange={this.handleChange}>
													<option value={this.state.selectVal}>{this.state.selectText}</option>
													<option value="Admin">Admin</option>
													<option value="Floor worker">Floor Worker</option>
												</select>
											</div>
									</div>
								</div>
															
							</div>
						</div>
						
						<h5>Update Password</h5>
						<div className="row">
							<div className="col-md-6">
								
								<div className="form-group row px-0">
									<div className="col-md-3 px-0">
									<label htmlFor="get_newPass" className="col-form-label text-md-right">Password</label></div>
									<div className="col-md-9 pr-0">
										<input type="password" id="get_newPass" className="form-control" name="get_newPass" placeholder="New password" value={this.state.get_newPass || ''}  onChange={this.handleChange}/>
									</div>
								</div>

								
							</div>
							<div className="col-md-6">
							
							<div className="form-group row px-0">
									<div className="col-md-3 px-0">
									<label htmlFor="get_newPass_2" className="col-form-label text-md-right">Confirm</label></div>
									<div className="col-md-9 pr-0">
										<input type="password" id="get_newPass_2" className="form-control" name="get_newPass_2" placeholder="Confirm new password" value={this.state.get_newPass_2 || ''}  onChange={this.handleChange}/>
									</div>


								</div>
															
							</div>
						</div>

						<div className="form-group row ">
							<div className="col-md-12 px-0 text-right">

								<button type="button" onClick={() => {this.deleteBtn()} }  className="delete-btn delete-user-btn btn btn-default">
									Delete
								</button>

								<button type="submit" className="add-user-btn btn btn-default">
									{this.state.ButtonText}
								</button>
							</div>
						</div>

					</form>
                </div>			
            </div>
        </div>
        </div>
	)
}


confirmDeleteHTML = () =>{
	return(        
        <div className={" modal fade "+this.state.confirmDeleteModal} id="confirmModal" tabIndex="-1" role="dialog" aria-labelledby="addNewUser" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered animated fadeIn" role="document">
          <div className="modal-content">
                <div className="modal-header">
                    <span onClick={() => {this.closeDelete()} } data-dismiss="modal" className="ai-close-icon"><img src="assets/img/close-icon.svg" alt="close"/></span>
                    <h3 className="modal-title">Delete User</h3>
                </div>
                <div className="modal-body">

						<h6>Are you sure you want to delete "{this.state.get_firstName} {this.state.get_lastName}" ?</h6>
						<p>This user will be deleted immediately. You can’t undo this action.</p>		

						<div className="form-group row confirmBtns">
							<div className="col-md-12 px-0 text-right">

								<button type="button" onClick={() => {this.deleteUserModal()} }  className="delete-btn delete-user-btn btn btn-default">
									DELETE
								</button>

								<button onClick={() => {this.closeDelete()} } type="button" className="add-user-btn btn btn-default">
									cancel
								</button>
							</div>
						</div>

                </div>			
            </div>
        </div>
        </div>
	)
}

 render(){
	 return(
     
		<div className="tab-pane" id="users" role="tabpanel" aria-labelledby="users-tab">
		<div className="row">
		  <div className="col-md-12">
			  <h3>Users</h3>
		  </div>
		</div>
		<div className="row">
		  <div className="col-md-12">
		
			<table className="table">
				<thead>
					<tr>
						<th>Name</th>
						<th>Email</th>
						<th>Profile type</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
				{ this.state.usersDetails.map((user, index) => 
					<tr key={index}>
						<td>{user.first_name} {user.last_name}</td>
						<td>{user.user_email}</td>
						<td>{user.user_type}</td>
						<td className="td-button-col"><a href="#" onClick={() => {this.editUserModal("Edit User", user.user_email)} } className="ai-btn-edit">Edit</a></td>
					</tr>
				)}
				</tbody>
			</table>
			<a href="#" onClick={() => {this.openModal("Add User")} } className="btn btn-default pull-right" data-toggle="modal" data-target="#userModal">Add new user</a>

		  </div>
		</div>

		{this.newUserModalHTML()}

		{this.confirmDeleteHTML()}

	  </div>
      
	 )
 }
}

export default Users
