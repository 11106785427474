import React, {Component} from 'react'
import { Redirect, Link } from "react-router-dom";
import Cookies from 'js-cookie';


import Navbar from '../components/Navbar';
import SideNavbar from '../components/profile/SideNavbar';
import ProfileDetails from '../components/profile/ProfileDetails';
import UpdatePassword from '../components/profile/UpdatePassword';
import Users from '../components/profile/Users';
import Reminders from '../components/profile/Reminders';
import SoldPallets from '../components/profile/SoldPallets';
import ColdStorage from '../components/profile/ColdStorage';
import Factories from '../components/profile/Factories';
import Coldrooms from '../components/profile/Coldrooms';
import CutTypes from '../components/profile/CutTypes';
import TotalCounts from '../components/profile/TotalCounts';

import AlertModal from '../components/Modals/alertModal';

import * as appConst from '../functions/Constants';
const apiUrl = appConst.API_URL;

class CreateProfile extends Component{

// Class constructor 
constructor(props){

	super(props);
    this.state = {
      error: null,
      isLoaded: false,

      api_key : Cookies.get('meatco_cookie') ,
      user_id : Cookies.get('meatco_user') ,
      alertModalOpen :'',
      alertSubText :'',
      alertBodyText : '',
      alertHeadingText : '',
    };
}


closeModal = () =>{
	this.setState({
		alertModalOpen : ""
	}); 
}

openAlertModal = (BodyText, SubText, HeadingText ) =>{
	this.setState({
    alertModalOpen : "show",
    alertBodyText : BodyText,
    alertSubText : SubText,
    alertHeadingText : HeadingText,
  });

}



render(){

  const cookieExists = Cookies.get('meatco_cookie');
  const userExist = Cookies.get('meatco_user');
  if(!cookieExists || !userExist){
    return <Redirect to="/login" />
  }else{

  return (
    <div className="profile-page">
    <div className="wrapper ">
      <div className="main-panel">

        <nav id="top-navbar" className="navbar navbar-expand-lg navbar-default navbar-absolute fixed-top ">
          <Navbar/>
        </nav>

        <div className="content">
          <div className="container">
            <div className="row justify-content-center my-4">
              
              <div className="col-md-3 pr-0 side-section">
                <SideNavbar/>

                <Link className='btn btn-default btn-fill mt-5 back-to-dashboard' to="/">
                    Back to dashboard
                  <div className="ripple-container"></div><div className="ripple-container"></div>
                </Link>

              </div>

              <div className="col-md-6 main-section">
                <div className="tab-content" id="myTabContent">
                  <ProfileDetails 
                    apiUrl={apiUrl}
                    api_key={this.state.api_key} 
                    user_id={this.state.user_id}
                    openAlertModal = {this.openAlertModal.bind(this)}
                  />

                  <UpdatePassword 
                    apiUrl={apiUrl}
                    api_key={this.state.api_key} 
                    user_id={this.state.user_id}
                    emailAddress="ephraim@aglet.co.za"
                    openAlertModal = {this.openAlertModal.bind(this)}
                  />
                  <Users 
                    openAlertModal = {this.openAlertModal.bind(this)}
                  />
                  <Reminders />
                  <SoldPallets />
                  <ColdStorage 
                    openAlertModal = {this.openAlertModal.bind(this)}
                  />
                  <Factories 
                    openAlertModal = {this.openAlertModal.bind(this)}
                  />
                  <Coldrooms 
                    openAlertModal = {this.openAlertModal.bind(this)}
                  />
                  <CutTypes 
                    openAlertModal = {this.openAlertModal.bind(this)}
                  />
                  <TotalCounts 
                    openAlertModal = {this.openAlertModal.bind(this)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
       
      </div>
    </div>

    <AlertModal 
      closeModal = {this.closeModal.bind(this)}
      modalClass = {this.state.alertModalOpen}
      alertModalOpen = {this.state.alertModalOpen}
      alertSubText = {this.state.alertSubText}
      alertBodyText = {this.state.alertBodyText}
      alertHeadingText = {this.state.alertHeadingText}
    />


  </div>
  );
  }
}

}

export default CreateProfile;
