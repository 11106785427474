import React, {Component} from 'react'
import axios from "axios";
import Moment from 'react-moment';
import {Link} from "react-router-dom";
import Cookies from 'js-cookie';
import { CSVLink, CSVDownload } from "react-csv";

import * as appConst from '../../functions/Constants';
const apiUrl = appConst.API_URL;

class SoldPallets extends Component {

	constructor(props){

		super(props);
		this.state = {
			error: null,
			actionMessage : '',
			isLoaded: false,
			soldPallets : [],
		};
	
	
	}
	
	
	// HTTP request to get All users
	getSoldPallets = () => {
		axios.get(apiUrl+'/pallets/sold-pallets', 
		  {
			  headers: {
				  Authorization: Cookies.get('meatco_cookie'),
				  'Content-Type': 'application/json'
			  }
		  })
		.then(res => {
		  this.setState({ 
			soldPallets : res.data,
		  });
	   
		})
		.catch(err => {
		  this.setState({ 
			error: "There was a technical problem while fetching sold pallets.",
			actionMessage : "There was a technical problem while fetching sold pallets."
		  });
		  this.props.openAlertModal(this.state.actionMessage, "", "Error");
		});
	}
	
	
	
	
	componentDidMount() {
		this.getSoldPallets();  
	}
	


	

 render(){

	var csvData = [
        ["", "Factory Name", "Coldroom name", "Palette ID", "Date sold"],
      ];

    this.state.soldPallets.map((pallete, index) => 
        csvData.push(
            [index+1, pallete.factory_name, pallete.coldroom_name, pallete.pallet_id, pallete.updated_at],
        ),
	)

	 return(
     
		<div className="tab-pane" id="sold-palettes" role="tabpanel" aria-labelledby="sold-palettes-tab">
		<div className="row">
		  <div className="col-md-12">
			  <h3>Sold palettes</h3>
		  </div>
		</div>
		<div className="row">
		  <div className="col-md-12">
			<table className="table">
				<thead>
					<tr>
						<th>Factory name</th>
						<th>Coldroom name</th>
						<th>Palette ID</th>
						<th>Date sold</th>
					</tr>
				</thead>
				<tbody>

				{ this.state.soldPallets.map((pallete, index) => 
					<tr key={index}>
						<td>{pallete.factory_name}</td>
						<td>{pallete.coldroom_name}</td>
						<td>{pallete.pallet_id}</td>
						<td className="not-button-col"><Moment format="DD MMMM YYYY">{pallete.updated_at}</Moment></td>
					</tr>
				)}

				</tbody>
			</table>
		  </div>
		</div>
		
		<div className="row">
		  <div className="col-md-12">
            <CSVLink
                data={csvData}
                filename={"sold-pallets.csv"}
                className="btn btn-default pull-right download-data"
                target="_blank"
            >
                <img src="/assets/img/download.svg"/> Download data
            </CSVLink>;

		  </div>
		</div>

	  </div>
      
	 )
 }
}

export default SoldPallets
