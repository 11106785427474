import React, {Component} from 'react'
import {Link } from "react-router-dom";
import axios from 'axios';
import Cookies from 'js-cookie';


import * as appConst from '../../functions/Constants';
const apiUrl = appConst.API_URL;

class LoginForm extends Component {

  constructor(props){
    super(props);

    this.state = {
        user_email:"",
        user_password:"",
        errorMessages: "",
        responseStatus: [],
        inputErrors:""  ,
        loginView:true,
        forgotPasswordView:false,
        resetPasswordView:false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
}

handleChange(event){
    this.setState({
        [event.target.name]: event.target.value 
    });
}

handleSubmit(event){
    const {user_email, user_password} = this.state;
    
    const data = {
      'user_email': user_email,
      'user_password': user_password,
    }
    
    const headers = {
      'Content-Type': 'application/json',
    }

    axios.post(apiUrl+"/user/login",data,{
      headers: headers
    })
    .then(response => {

      if(response.data.status == "error"){
        this.setState({errorMessages: response.data.error_msg})
        this.setState({inputErrors: "form-control form-input-fail"})
      }else {
        
        this.setState({errorMessages:null});
        Cookies.set('meatco_cookie', response.data.api_key, { expires: 1 });
        Cookies.set('meatco_user', response.data.user, { expires: 1 });
        this.props.handleLogin();
      }

    })
    .catch(error => {
      if (this.state.user_email === "" && this.state.user_password === "") {
        this.setState({errorMessages:'Fields cannot be empty'})
        this.setState({inputErrors: "form-control form-input-fail"})
      }else if (this.state.user_email === "") {
        this.setState({errorMessages:'Please enter email'})
        this.setState({inputErrors: "form-control form-input-fail"})
      }else if (this.state.user_password === "") {
        this.setState({errorMessages:'Please enter password'})
        this.setState({inputErrors: "form-control form-input-fail"})
      }
      
    });
    event.preventDefault();
}

 render(){
  var handleLogin = this.props.handleLogin;
	 return(
        <form onSubmit={this.handleSubmit}>
          
        { this.state.errorMessages && <div className="alert alert-danger alert-dismissible fade show"> { this.state.errorMessages } </div> }
        <div className="form-group row px-0 bmd-form-group">
          <div className="col-md-3">
            <label htmlFor="email_address" className="col-form-label text-md-right">Email</label>
            </div>
            <div className="col-md-9 pr-0">
                <input type="email" id="email_address" className={this.state.inputErrors ? "form-control form-input-fail" : "form-control"} name="user_email" placeholder="Email address" required="" value={this.state.user_email} onChange={this.handleChange} />
            </div>
        </div>
        
        <div className="form-group row px-0 bmd-form-group">
          <div className="col-md-3">
            <label htmlFor="password" className="col-form-label text-md-right">Password</label>
            </div>
            <div className="col-md-9 pr-0">
                <input type="password" id="password" className={this.state.inputErrors ? "form-control form-input-fail" : "form-control"} name="user_password" placeholder="Password" required="" value={this.state.user_password} onChange={this.handleChange} />
            </div>
        </div>
        
        <div className="form-group row pr-0">
          <div className="col-md-12 px-0">
            <button type="submit">Login</button>
            
            <Link to="forgot-password" className="btn btn-link forgot-password pull-right pr-0">Forgot Password?</Link>
          </div> 
        </div>
        </form>
	 )
 }
}

export default LoginForm



