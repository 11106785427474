import React, {Component} from 'react'
import axios from "axios";
import Moment from 'react-moment';
import {Link} from "react-router-dom";
import Cookies from 'js-cookie';

import * as appConst from '../../functions/Constants';
const apiUrl = appConst.API_URL;

class CutTypes extends Component {



	constructor(props){

		super(props);
		this.state = {
			error: null,
			actionMessage : '',
			isLoaded: false,
			confirmModalVisible:false,
			addNewModalVisible:false,			
			addCutTypeModalOpen :'',
			alertModalOpen :'',
			  
			edit_cuttype_id : '',
			edit_cuttype_name : '',
			modal_title : '',
			ButtonText : '',
			submitAction : '',
			confirmDeleteModal : '',

			TextInput_CutTypeName : '',
			TextInput_CategoryId : '',

			allCutTypes : [],
			allCategories : [],
		};
		
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleCategoryChange = this.handleCategoryChange.bind(this);
	
	}

	
// HTTP request to get All Coldrooms
getCutTypes = () => {
	axios.get(apiUrl+'/cut-type/from-category/'+this.state.TextInput_CategoryId, 
		{
			headers: {
				Authorization: Cookies.get('meatco_cookie'),
				'Content-Type': 'application/json'
			}
		})
	.then(res => {

		if(res.data.status === "error"){
			this.setState({ 
				actionMessage : res.data.msg,
				allCutTypes : [],
			});
			this.props.openAlertModal(this.state.actionMessage, " ", "Error!");
			// alert(this.state.actionMessage);
		}else{
			this.setState({ 
				allCutTypes : res.data,
			});
		}

	
	})
	.catch(err => {
		this.setState({ 
			error: "There was a technical problem while fetching coldrooms.",
			actionMessage : "There was a technical problem while fetching coldrooms."
		});
		this.props.openAlertModal(this.state.actionMessage, "", "Error");
		// alert(this.state.actionMessage);
	});

}

// HTTP request to get All categories
getCategories = () => {
	axios.get(apiUrl+'/cut-type/list-categories', 
		{
			headers: {
				Authorization: Cookies.get('meatco_cookie'),
				'Content-Type': 'application/json'
			}
		})
	.then(res => {

		
		if(res.data.status === "error"){
			this.setState({ 
				actionMessage : res.data.msg,
				allCategories : res.data,
			});
			this.props.openAlertModal(this.state.actionMessage, " ", "Error!");
			// alert(this.state.actionMessage);
		  }else{
			this.setState({ 
				allCategories : res.data,
			});

			if(this.state.TextInput_CategoryId === ""){
				this.setState({ 
					TextInput_CategoryId : res.data[0].category_name
				});
			}

			// alert(this.state.TextInput_CategoryId)
			

			this.getCutTypes();

		  }
	
	})
	.catch(err => {
		this.setState({ 
			error: "There was a technical problem while fetching categories.",
			actionMessage : "There was a technical problem while fetching categories."
		});
		this.props.openAlertModal(this.state.actionMessage, "", "Error");
		// alert(this.state.actionMessage);
	});
}

// HTTP request to update cut-type
updateCutTypeDetails = (cut_type_id) => {

	const data = {
		'cut_type_name': this.state.TextInput_CutTypeName,
	}
  
	const headers = {
	  Authorization: Cookies.get('meatco_cookie'),
	  'Content-Type': 'application/json',
	}
  
	axios.put(apiUrl+'/cut-type/update/'+this.state.edit_cuttype_id,data,{
	  headers: headers
	})
	.then(res => {
		
	  this.closeModal();
	  
	  if(res.data.status === "error"){
		this.setState({ 
			actionMessage : res.data.msg,
		});
		this.props.openAlertModal(this.state.actionMessage, " ", "Error!");
	  }else{
		this.setState({ 
			actionMessage : res.data.msg,
			confirmModalVisible:false,
			addNewModalVisible:false,
		});

		this.getCutTypes(); 

		this.props.openAlertModal(this.state.actionMessage, " ", "Success!");
		// alert(this.state.actionMessage);
	  }
  
	})
	.catch(err => {
	  this.setState({ 
		actionMessage: "There was a technical problem while updating cut-type information.",
		confirmModalVisible:false,
			addNewModalVisible:false,
	  })

	  console.log(err)
  
	  this.props.openAlertModal(this.state.actionMessage, "", "Error");
	// alert(this.state.actionMessage);
  
	});
  
}

// HTTP request to delete cut-type
deleteCutType = (cut_type_id) => {
	axios.delete(apiUrl+'/cut-type/delete/'+cut_type_id, 
	  {
		  headers: {
			  Authorization: Cookies.get('meatco_cookie')
		  }
	  })
	.then(res => {
		
	  this.closeModal();
	  
	  this.setState({ 
		actionMessage : res.data.msg,
		addCutTypeModalOpen : "",
	  });

	  if(res.data.status === "error"){
		this.setState({ 
			actionMessage : res.data.msg,
		});
		this.props.openAlertModal(this.state.actionMessage, " ", "Error!");
		// alert(this.state.actionMessage);
	  }else{
		this.setState({ 
			actionMessage : res.data.msg,
			confirmModalVisible:false,
			addNewModalVisible:false,
		});

		this.getCutTypes(); 

		this.props.openAlertModal(this.state.actionMessage, " ", "Success!");
		// alert(this.state.actionMessage);
	  }

	})
	.catch(err => {
	  this.setState({ 
		error: "There was a technical problem while deleting the cut-type.",
		actionMessage : "There was a technical problem while deleting the cut-type.",
		confirmModalVisible:false,
			addNewModalVisible:false,
	  });

	  this.props.openAlertModal(this.state.actionMessage, " ", "Success!");
	// alert(this.state.actionMessage);

	});
}


// HTTP request to get cut-type
getSingleCutType = (cut_type_id) => {

	axios.get(apiUrl+'/cut-type/get/'+cut_type_id, 
	  {
		  headers: {
			  Authorization: Cookies.get('meatco_cookie')
		  }
	  })
	.then(res => {
	  
	  this.setState({ 
		TextInput_CutTypeName : res.data[0].cut_type_name,
	  });


	})
	.catch(err => {
	  this.setState({ 
		error: "There was a technical problem while fetching the cut-type.",
		actionMessage : "There was a technical problem while fetching the cut-type.",
		confirmModalVisible:false,
			addNewModalVisible:false,
	  });

	  this.props.openAlertModal(this.state.actionMessage, "", "Error");
	// alert(this.state.actionMessage);

	});
}


// HTTP request to add new cut-type
CreateNewCutType = () => {

	const data = {
		'cut_type_name': this.state.TextInput_CutTypeName,
		'category_id': this.state.TextInput_CategoryId,
	}
  
	const headers = {
	  Authorization : Cookies.get('meatco_cookie'),
	  'Content-Type': 'application/json',
	  Accept : 'application/json'
	}
  
	axios.post(apiUrl+'/cut-type/create',data,{
	  headers: headers
	})
	.then(res => {
	  
	  this.closeModal();
	  this.getCutTypes();

	  if(res.data.status === "error"){
		this.setState({ 
			actionMessage : res.data.msg,
		});
		this.props.openAlertModal(this.state.actionMessage, " ", "Error!");
		// alert(this.state.actionMessage);
	  }else{
		this.setState({ 
			actionMessage : res.data.msg,
			confirmModalVisible:false,
			addNewModalVisible:false,
		});

		this.props.openAlertModal(this.state.actionMessage, " ", "Success!");

	  }
	  
	})
	.catch(err => {
	  this.setState({ 
		actionMessage: "There was a technical problem while creating the cut-type.",
		confirmModalVisible:false,
			addNewModalVisible:false,
	  });
	  this.props.openAlertModal(this.state.actionMessage, " ", "Success!");
	});
}



handleChange(event) {

	this.setState({
		[event.target.name]: event.target.value
	});

}

handleCategoryChange(event) {
	event.preventDefault();	
	this.setState({
		[event.target.name]: event.target.value
	});

	this.getCategories();
}


	
handleSubmit(event) {
	event.preventDefault();	
	if(this.state.submitAction === "add"){
	
		if( this.state.TextInput_CutTypeName === "")
		{
			this.props.openAlertModal("All fields must be filled in.", " ", "Error!");
	
		}else{
			this.CreateNewCutType();
			this.getCutTypes()
		}
		
	}
	
	if(this.state.submitAction === "edit"){
		if( this.state.TextInput_CutTypeName === "")
		{
			this.props.openAlertModal("All fields must be filled in.", " ", "Error!");
	
		}else{
			this.updateCutTypeDetails();
			this.getCutTypes();
		}
	}
	
}


closeModal = () =>{
	this.setState({
		addCutTypeModalOpen : "",
		modal_title : '',
		edit_cuttype_id : '',
		edit_cuttype_name : '',
		TextInput_CutTypeName : '',
		ButtonText : '',
		submitAction : '',
		confirmDeleteModal : '',
	}); 
}

closeDelete = () =>{
	this.setState({
		confirmDeleteModal : '',
	}); 
}

addNewModal = (title) =>{
	this.setState({
		addCutTypeModalOpen : "show",
		modal_title : title,
		ButtonText : 'Add cut-type',
		submitAction : 'add',
		TextInput_CutTypeName : '',
	});
}

editModal = (title, cut_type_id, cut_type_name) =>{
	this.setState({
		addCutTypeModalOpen : "show",
		edit_cuttype_id : cut_type_id,
		edit_cuttype_name : cut_type_name,
		modal_title : title,
		ButtonText : 'Update cut type',
		submitAction : 'edit',
	});

	this.getSingleCutType(cut_type_id);

}


deleteCutTypeModal = () =>{
	this.deleteCutType(this.state.edit_cuttype_id);
	this.getCutTypes();  
}

deleteBtn= () =>{
	this.setState({
		confirmDeleteModal : 'show',
	});
}	

componentDidMount() {
	this.getCategories();
	
}
	

newCutTypeModalHTML = () =>{
	return(        
		<div className={this.state.submitAction+" modal fade "+this.state.addCutTypeModalOpen} id="alertModal" tabIndex="-1" role="dialog" aria-labelledby="addNewUser" aria-hidden="true">
		<div className="modal-dialog modal-dialog-centered animated fadeIn" role="document">
			<div className="modal-content">
				<div className="modal-header">
					<span onClick={() => {this.closeModal()} } data-dismiss="modal" className="ai-close-icon"><img src="assets/img/close-icon.svg" alt="close"/></span>
					<h3 className="modal-title">{this.state.modal_title}</h3>
				</div>
				<div className="modal-body">
					
					<form onSubmit={this.handleSubmit}>
						<div className="row">
							<div className="col-md-12">
								
								<div className="form-group row px-0">
									<div className="col-md-3 px-0">
									<label htmlFor="TextInput_CutTypeName" className="col-form-label text-md-right">Cut-type Name</label></div>
									<div className="col-md-9 pr-0">
										<input type="text" id="TextInput_CutTypeName" className="form-control" name="TextInput_CutTypeName" placeholder=" " value={this.state.TextInput_CutTypeName || ''} required onChange={this.handleChange} />
									</div>
								</div>
								
							</div>
						</div>
						
		
						<div className="form-group row ">
							<div className="col-md-12 px-0 text-right">

								<button type="button" onClick={() => {this.deleteBtn()} }  className="delete-btn delete-cut-type-btn btn btn-default">
									Delete
								</button>

								<button type="submit" className="add-cut-type-btn btn btn-default">
									{this.state.ButtonText}
								</button>
							</div>
						</div>

					</form>
				</div>			
			</div>
		</div>
		</div>
	)
}


confirmDeleteHTML = () =>{
	return(        
		<div className={" modal fade "+this.state.confirmDeleteModal} id="confirmModal" tabIndex="-1" role="dialog" aria-labelledby="addNewUser" aria-hidden="true">
		<div className="modal-dialog modal-dialog-centered animated fadeIn" role="document">
			<div className="modal-content">
				<div className="modal-header">
					<span onClick={() => {this.closeDelete()} } data-dismiss="modal" className="ai-close-icon"><img src="assets/img/close-icon.svg" alt="close"/></span>
					<h3 className="modal-title">Delete cut type</h3>
				</div>
				<div className="modal-body">

						<h6>Are you sure you want to delete "{this.state.edit_cuttype_name}" ?</h6>
						<p>This cut-type will be deleted immediately. You can’t undo this action.</p>		

						<div className="form-group row confirmBtns">
							<div className="col-md-12 px-0 text-right">

								<button type="button" onClick={() => {this.deleteCutTypeModal()} }  className="delete-btn delete-cut-type-btn btn btn-default">
									DELETE
								</button>

								<button onClick={() => {this.closeDelete()} } type="button" className="add-cut-type-btn btn btn-default">
									cancel
								</button>
							</div>
						</div>

				</div>			
			</div>
		</div>
		</div>
	)
}
	







 render(){
	 return(
     
		<div className="tab-pane" id="cut-types" role="tabpanel" aria-labelledby="cut-types-tab">
		<div className="row">
		  <div className="col-md-12">
			  <h3>Cut types</h3>
		  </div>
		</div>

		<div className="row">
			<div className="col-md-12">
				
				<div className="form-group row px-0">
					<div className="col-md-3 px-0">
					<label htmlFor="input_CategoryId" className="col-form-label text-md-right">Select a category</label></div>
					<div className="col-md-9 pr-0">

						<select className="form-control custom-select" id="input_CategoryId" name="input_CategoryId" required onChange={this.handleCategoryChange}>
							
							{ this.state.allCategories.map((category, index) => 
								<option key={index} value={category.category_id}>{category.category_name}</option>
							)}

						</select>

					</div>
				</div>
				
			</div>
		</div>

		<div className="row">
		  <div className="col-md-12">
			<table className="table">
				<thead>
					<tr>
						<th></th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					
				{ this.state.allCutTypes.map((CutType, index) => 
					<tr key={index}>
						<td>{CutType.cut_type_name}</td>
						<td className="td-button-col"><a href="#" onClick={() => {this.editModal("Edit cut type", CutType.cut_type_id, CutType.cut_type_name)} } className="ai-btn-edit">Edit</a></td>
					</tr>
				)}

				</tbody>
			</table>
		  </div>
		</div>
		
		<div className="row">
		  <div className="col-md-12">
			<a href="#" onClick={() => {this.addNewModal("Add cut type")} } className="btn btn-default pull-right">Add new cut type</a>
		  </div>
		</div>

		{this.newCutTypeModalHTML()}

		{this.confirmDeleteHTML()}

	  </div>
      
	 )
 }
}

export default CutTypes
