import React, {Component} from 'react'

class SideNavbar extends Component {
 render(){
	 return(
       
        <ul className="nav nav-pills flex-column" id="myTab" role="tablist">
            <li className="nav-item">
            <a className="nav-link active" id="profile-detail-tab" data-toggle="tab" href="#profile-detail" role="tab" aria-controls="home" aria-selected="true">Profile details</a>
            </li>
            <li className="nav-item">
            <a className="nav-link" id="password-tab" data-toggle="tab" href="#password" role="tab" aria-controls="profile" aria-selected="false">Password</a>
            </li>
            <li className="nav-item">
            <a className="nav-link" id="users-tab" data-toggle="tab" href="#users" role="tab" aria-controls="users" aria-selected="false">Users </a>
            </li>
            <li className="nav-item">
                <a className="nav-link" id="reminders-tab" data-toggle="tab" href="#reminders" role="tab" aria-controls="reminders" aria-selected="false">Reminders </a>
            </li>
            <li className="nav-item">
                <a className="nav-link" id="sold-palettes-tab" data-toggle="tab" href="#sold-palettes" role="tab" aria-controls="sold-palettes" aria-selected="false">Sold palettes </a>
            </li>
            <li className="nav-item">
                <a className="nav-link" id="cold-storage-tab" data-toggle="tab" href="#cold-storage" role="tab" aria-controls="cold-storage" aria-selected="false">Cold storage </a>
            </li>
            <li className="nav-item">
                <a className="nav-link" id="factories-tab" data-toggle="tab" href="#factories" role="tab" aria-controls="factories" aria-selected="false">Factories </a>
            </li>
            <li className="nav-item">
                <a className="nav-link" id="coldrooms-tab" data-toggle="tab" href="#coldrooms" role="tab" aria-controls="coldrooms" aria-selected="false">Coldrooms </a>
            </li>
            <li className="nav-item">
                <a className="nav-link" id="cut-types-tab" data-toggle="tab" href="#cut-types" role="tab" aria-controls="cut-types" aria-selected="false">Cut types </a>
            </li>
            <li className="nav-item">
                <a className="nav-link" id="total-count-tab" data-toggle="tab" href="#total-count" role="tab" aria-controls="total-count" aria-selected="false">Total Counts </a>
            </li>

        </ul>
        
        
	 );
 }
}

export default SideNavbar
