import React, {Component} from 'react'
import { Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import logo from '../assets/logo.svg';

import ForgotPasswordForm from '../components/login/ForgotForm';


class forgotPassword extends Component {
  constructor(props){
    super(props);
    this.state = {
   
    }
  }

  
  componentDidMount(){
    
  }


 render(){
  const cookieExists = Cookies.get('meatco_cookie');
  const userExist = Cookies.get('meatco_user');
  if(cookieExists && userExist){
    return <Redirect to="/" />
  }else{

	 return(
         <div className="login-page">
            <div className="wrapper">
                <div className="content">
                <div className="content">
                <div className="container h-100">
                <div className="row align-items-center h-100">
                    <div className="col-md-5 mx-auto">
      
                <div className="card-avatar text-center">
                    <a href="#pablo">
                      <img src={logo} className="img"  alt="Meatco Logo"/>
                    </a>
                  </div>
                <div className="card">
                  <div className="card-body">            
                 
                    <ForgotPasswordForm />
                  
                  </div>
              </div>
          </div>
        </div>
        </div>
        </div>
    </div>
    </div>
    </div>
	 )
 }
}
}

export default forgotPassword
