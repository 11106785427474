import React, {Component} from 'react';
import Moment from 'react-moment';


class ExpiryModal extends Component {

componentDidMount(){
    
}

closeModal = () =>{
    this.props.closeModal()
}

 render(){

    // console.log(this.props.currentFactory)

	 return(

        
		
        <div className="modal-dialog modal-dialog-centered animated fadeIn" role="document">
          <div className="modal-content">
            <div className="modal-header">
                <span onClick={() => {this.closeModal()} } data-dismiss="modal" className="ai-close-icon"><img src="assets/img/close-icon.svg" alt="close"/></span>
                <h4 className="modal-title" id="reminderpalettesLabel">Expiring palettes</h4>
                <p>The below palettes are expiring this week in “{this.props.currentColdroomName}”:</p>
            </div>
            <div className="modal-body">
                
                    <table className="table">
                            <thead>
                                <tr>
                                    <th>Palette name</th>
                                    <th>Palette ID</th>
                                    <th>Expiry date</th>
                                    
                                </tr>
                            </thead>
                            <tbody>

                                { this.props.expiringPallets.map((pallet, index) => 
                                    <tr key={index} className={pallet.status}>
                                        <td>{pallet.pallet_name}</td>
                                        <td>{pallet.pallet_id}</td>
                                        <td><Moment format="DD MMMM YYYY">{pallet.expiry_date}</Moment></td>

                                    </tr>
                                )}
                            
                        </tbody>
                    </table>
            </div>
            <div className="expiry-modal-footer modal-footer">
            <div className="form-group row ">
                  <div className="col-md-12 px-0 text-right">
                      <button onClick={() => {this.closeModal()} } data-dismiss="modal" type="button" className="btn btn-default">
                        Cancel
                      </button>
                  </div>
            </div>
          </div>
        </div>

    </div>

     )
 }
}

export default ExpiryModal
