import React, {Component} from 'react'
import axios from "axios";
import Moment from 'react-moment';
import {Link} from "react-router-dom";
import Cookies from 'js-cookie';

import * as appConst from '../../functions/Constants';
const apiUrl = appConst.API_URL;

class Coldrooms extends Component {

	constructor(props){

		super(props);
		this.state = {
			error: null,
			actionMessage : '',
			isLoaded: false,
			addColdroomModalOpen :'',
			alertModalOpen :'',
			  
			edit_coldroom_id : '',
			edit_coldroom_name : '',
			modal_title : '',
			ButtonText : '',
			submitAction : '',
			confirmDeleteModal : '',

			input_ColdroomName : '',
			input_factoryId : '',

			allColdrooms : [],
			allFcatories : [],
		};
		
		this.handleChange = this.handleChange.bind(this);
    	this.handleSubmit = this.handleSubmit.bind(this);
		this.handleFactoryChange = this.handleFactoryChange.bind(this);
	}

	
// HTTP request to get All Coldrooms
getColdrooms = () => {
	axios.get(apiUrl+'/coldroom/from-factory/'+this.state.input_factoryId, 
		{
			headers: {
				Authorization: Cookies.get('meatco_cookie'),
				'Content-Type': 'application/json'
			}
		})
	.then(res => {

		if(res.data.status === "error"){
			this.setState({ 
				actionMessage : res.data.msg,
				allColdrooms : [],
			});
			this.props.openAlertModal(this.state.actionMessage, " ", "Error!");
		}else{
			this.setState({ 
				allColdrooms : res.data,
			});
		}

	
	})
	.catch(err => {
		this.setState({ 
			error: "There was a technical problem while fetching coldrooms.",
			actionMessage : "There was a technical problem while fetching coldrooms."
		});
		this.props.openAlertModal(this.state.actionMessage, "", "Error");
	});

}

// HTTP request to get All Factories
getFactories = () => {
	axios.get(apiUrl+'/factories/list', 
		{
			headers: {
				Authorization: Cookies.get('meatco_cookie'),
				'Content-Type': 'application/json'
			}
		})
	.then(res => {

		
		if(res.data.status === "error"){
			this.setState({ 
				actionMessage : res.data.msg,
				allFcatories : res.data,
			});
			this.props.openAlertModal(this.state.actionMessage, " ", "Error!");
		  }else{
			this.setState({ 
				allFcatories : res.data,
			});

			if(this.state.input_factoryId === ""){
				this.setState({ 
					input_factoryId : res.data[0].factory_id
				});

			}
			this.getColdrooms();
		  }
	
	})
	.catch(err => {
		this.setState({ 
			error: "There was a technical problem while fetching factories.",
			actionMessage : "There was a technical problem while fetching factories."
		});
		this.props.openAlertModal(this.state.actionMessage, "", "Error");
	});
}

// HTTP request to update coldroom
updateColdroomDetails = (coldroom_id) => {

	const data = {
		'coldroom_name': this.state.input_ColdroomName,
	}
  
	const headers = {
	  Authorization: Cookies.get('meatco_cookie'),
	  'Content-Type': 'application/json',
	}
  
	axios.put(apiUrl+'/coldroom/update/'+this.state.edit_coldroom_id,data,{
	  headers: headers
	})
	.then(res => {
	  
	  if(res.data.status === "error"){
		this.setState({ 
			actionMessage : res.data.msg,
		});
		this.props.openAlertModal(this.state.actionMessage, " ", "Error!");
	  }else{
		this.setState({ 
			actionMessage : res.data.msg,
			addColdroomModalOpen : " ",
			confirmDeleteModal : '',
		});

		this.getColdrooms(); 

		this.props.openAlertModal(this.state.actionMessage, " ", "Success!");
	  }
  
	})
	.catch(err => {
	  this.setState({ 
		actionMessage: "There was a technical problem while updating coldroom information.",
		addColdroomModalOpen : '' 
	  })

	  console.log(err)
  
	  this.props.openAlertModal(this.state.actionMessage, "", "Error");
  
	});
  
}

// HTTP request to delete coldroom
deleteFactory = (coldroom_id) => {
	axios.delete(apiUrl+'/coldroom/delete/'+coldroom_id, 
	  {
		  headers: {
			  Authorization: Cookies.get('meatco_cookie')
		  }
	  })
	.then(res => {
	  
	  this.setState({ 
		actionMessage : res.data.msg,
		addColdroomModalOpen : "",
	  });

	  if(res.data.status === "error"){
		this.setState({ 
			actionMessage : res.data.msg,
			addColdroomModalOpen : '',
			confirmDeleteModal : '', 
		});
		this.getColdrooms(); 
		this.props.openAlertModal(this.state.actionMessage, " ", "Error!");
	  }else{
		this.setState({ 
			actionMessage : res.data.msg,
			addColdroomModalOpen : " ",
			confirmDeleteModal : '',
		});

		this.getColdrooms(); 

		this.props.openAlertModal(this.state.actionMessage, " ", "Success!");
	  }

	})
	.catch(err => {
	  this.setState({ 
		error: "There was a technical problem while deleting the coldroom.",
		actionMessage : "There was a technical problem while deleting the coldroom.",
		addColdroomModalOpen : ''
	  });
	  this.getColdrooms(); 
	  this.props.openAlertModal(this.state.actionMessage, " ", "Success!");

	});
}


// HTTP request to get coldroom
getSingleColdroom = (coldroom_id) => {

	axios.get(apiUrl+'/coldroom/get/'+coldroom_id, 
	  {
		  headers: {
			  Authorization: Cookies.get('meatco_cookie')
		  }
	  })
	.then(res => {
	  
	  this.setState({ 
		input_ColdroomName : res.data[0].coldroom_name,
	  });


	})
	.catch(err => {
	  this.setState({ 
		error: "There was a technical problem while fetching the coldroom.",
		actionMessage : "There was a technical problem while fetching the coldroom.",
		addColdroomModalOpen : '' 
	  });

	  this.props.openAlertModal(this.state.actionMessage, "", "Error");

	});
}


// HTTP request to add new coldroom
CreateNewColdroom = () => {

	const data = {
		'coldroom_name': this.state.input_ColdroomName,
		'factory_id': this.state.input_factoryId,
	}
  
	const headers = {
	  Authorization : Cookies.get('meatco_cookie'),
	  'Content-Type': 'application/json',
	  Accept : 'application/json'
	}
  
	axios.post(apiUrl+'/coldroom/create',data,{
	  headers: headers
	})
	.then(res => {
	  
	  
	  this.getColdrooms();

	  if(res.data.status === "error"){
		this.setState({ 
			actionMessage : res.data.msg,
		});
		this.props.openAlertModal(this.state.actionMessage, " ", "Error!");
	  }else{
		this.setState({ 
			actionMessage : res.data.msg,
			addColdroomModalOpen : " ",
		});

		this.props.openAlertModal(this.state.actionMessage, " ", "Success!");
	  }
	  
	})
	.catch(err => {
	  this.setState({ 
		actionMessage: "There was a technical problem while creating the coldroom.",
		addColdroomModalOpen : '' 
	  });
	  this.props.openAlertModal(this.state.actionMessage, " ", "Success!");
	});
}



handleChange(event) {
	this.setState({
		[event.target.name]: event.target.value
	});
}

handleFactoryChange(event) {
	this.setState({
		[event.target.name]: event.target.value
	});

	this.getFactories();
}
	
handleSubmit(event) {
	event.preventDefault();	
	
	if(this.state.submitAction === "add"){
	
		if( this.state.input_ColdroomName === "")
		{
			this.props.openAlertModal("All fields must be filled in.", " ", "Error!");
	
		}else{
			this.CreateNewColdroom();
			this.getColdrooms()
		}
		
	}
	
	if(this.state.submitAction === "edit"){
		if( this.state.input_ColdroomName === "")
		{
			this.props.openAlertModal("All fields must be filled in.", " ", "Error!");
	
		}else{
			this.updateColdroomDetails();
			this.getColdrooms();
		}
	}
	
}


closeModal = () =>{
	this.setState({
		addColdroomModalOpen : "",
		modal_title : '',
		edit_coldroom_id : '',
		edit_coldroom_name : '',
		input_ColdroomName : '',
		ButtonText : '',
		submitAction : '',
		confirmDeleteModal : '',
	}); 
}

closeDelete = () =>{
	this.setState({
		confirmDeleteModal : '',
	}); 
}

addNewModal = (title) =>{
	this.setState({
		addColdroomModalOpen : "show",
		modal_title : title,
		ButtonText : 'Add coldroom',
		submitAction : 'add',
		input_ColdroomName : '',
	});
}

editModal = (title, coldroom_id, coldroom_name) =>{
	this.setState({
		addColdroomModalOpen : "show",
		edit_coldroom_id : coldroom_id,
		edit_coldroom_name : coldroom_name,
		modal_title : title,
		ButtonText : 'Update',
		submitAction : 'edit',
	});

	this.getSingleColdroom(coldroom_id);
}


deleteUserModal = () =>{
	this.deleteFactory(this.state.edit_coldroom_id);
	this.getColdrooms();  
}

deleteBtn= () =>{
	this.setState({
		confirmDeleteModal : 'show',
	});
}	

componentDidMount() {
	this.getFactories();
	
}
	

newColdroomModalHTML = () =>{
	return(        
		<div className={this.state.submitAction+" modal fade "+this.state.addColdroomModalOpen} id="alertModal" tabIndex="-1" role="dialog" aria-labelledby="addNewUser" aria-hidden="true">
		<div className="modal-dialog modal-dialog-centered animated fadeIn" role="document">
			<div className="modal-content">
				<div className="modal-header">
					<span onClick={() => {this.closeModal()} } data-dismiss="modal" className="ai-close-icon"><img src="assets/img/close-icon.svg" alt="close"/></span>
					<h3 className="modal-title">{this.state.modal_title}</h3>
				</div>
				<div className="modal-body">
					
					<form onSubmit={this.handleSubmit}>
						<div className="row">
							<div className="col-md-12">
								
								<div className="form-group row px-0">
									<div className="col-md-3 px-0">
									<label htmlFor="input_ColdroomName" className="col-form-label text-md-right">coldroom Name</label></div>
									<div className="col-md-9 pr-0">
										<input type="text" id="input_ColdroomName" className="form-control" name="input_ColdroomName" placeholder=" " value={this.state.input_ColdroomName || ''} required onChange={this.handleChange} />
									</div>
								</div>
								
							</div>
						</div>
						
		
						<div className="form-group row ">
							<div className="col-md-12 px-0 text-right">

								<button type="button" onClick={() => {this.deleteBtn()} }  className="delete-btn delete-coldroom-btn btn btn-default">
									Delete
								</button>

								<button type="submit" className="add-coldroom-btn btn btn-default">
									{this.state.ButtonText}
								</button>
							</div>
						</div>

					</form>
				</div>			
			</div>
		</div>
		</div>
	)
}


confirmDeleteHTML = () =>{
	return(        
		<div className={" modal fade "+this.state.confirmDeleteModal} id="confirmModal" tabIndex="-1" role="dialog" aria-labelledby="addNewUser" aria-hidden="true">
		<div className="modal-dialog modal-dialog-centered animated fadeIn" role="document">
			<div className="modal-content">
				<div className="modal-header">
					<span onClick={() => {this.closeDelete()} } data-dismiss="modal" className="ai-close-icon"><img src="assets/img/close-icon.svg" alt="close"/></span>
					<h3 className="modal-title">Delete coldroom</h3>
				</div>
				<div className="modal-body">

						<h6>Are you sure you want to delete "{this.state.edit_coldroom_name}" ?</h6>
						<p>This coldroom will be deleted immediately. You can’t undo this action.</p>		

						<div className="form-group row confirmBtns">
							<div className="col-md-12 px-0 text-right">

								<button type="button" onClick={() => {this.deleteUserModal()} }  className="delete-btn delete-coldroom-btn btn btn-default">
									DELETE
								</button>

								<button onClick={() => {this.closeDelete()} } type="button" className="add-coldroom-btn btn btn-default">
									cancel
								</button>
							</div>
						</div>

				</div>			
			</div>
		</div>
		</div>
	)
}
	




 render(){
	 return(
     
		<div className="tab-pane" id="coldrooms" role="tabpanel" aria-labelledby="coldrooms-tab">
		<div className="row">
		  <div className="col-md-12">
			  <h3>Coldrooms</h3>
		  </div>
		</div>

		<div className="row">
			<div className="col-md-12">
				
				<div className="form-group row px-0">
					<div className="col-md-3 px-0">
					<label htmlFor="input_factoryId" className="col-form-label text-md-right">Select a factory</label></div>
					<div className="col-md-9 pr-0">
						{/* <input type="text" id="input_ColdroomName" className="form-control" name="input_ColdroomName" placeholder=" " value={this.state.input_ColdroomName || ''} required onChange={this.handleChange} /> */}

						<select className="form-control custom-select" id="input_factoryId" name="input_factoryId" required onChange={this.handleFactoryChange}>
							
							{ this.state.allFcatories.map((factory, index) => 
								<option key={index} value={factory.factory_id}>{factory.factory_name}</option>
							)}

						</select>

					</div>
				</div>
				
			</div>
		</div>

		<div className="row">
		  <div className="col-md-12">
			<table className="table">
				<thead>
					<tr>
						<th></th>
						<th></th>
					</tr>
				</thead>
				<tbody>

				{ this.state.allColdrooms.map((coldroom, index) => 
					<tr key={index}>
						<td>{coldroom.coldroom_name}</td>
						<td className="td-button-col"><a href="#" onClick={() => {this.editModal("Edit coldroom", coldroom.coldroom_id, coldroom.coldroom_name)} } className="ai-btn-edit">Edit</a></td>
					</tr>
				)}

				</tbody>
			</table>
		  </div>
		</div>
		
		<div className="row">
		  <div className="col-md-12">
			<a href="#" onClick={() => {this.addNewModal("Add coldroom")} } className="btn btn-default pull-right">Add new coldroom</a>
		  </div>
		</div>
		
		{this.newColdroomModalHTML()}

		{this.confirmDeleteHTML()}

	  </div>
      
	 )
 }
}

export default Coldrooms
