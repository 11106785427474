import React, {Component} from 'react';

class alertModal extends Component {

componentDidMount(){
    
}



closeModal = () =>{
  this.props.closeModal()
}

 render(){

	 return(
    <div className={"alertModal modal fade "+this.props.modalClass} id="alertModal" tabIndex="-1" role="dialog" aria-labelledby="alertModal" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered animated fadeIn" role="document">
          <div className="modal-content">
            <div className="modal-header">
                <span onClick={() => {this.closeModal()} } data-dismiss="modal" className="ai-close-icon"><img src="assets/img/close-icon.svg" alt="close"/></span>
                <h3 className="modal-title" id="reminderpalettesLabel">{this.props.alertHeadingText}</h3>
                <p>{this.props.alertSubText}</p>
            </div>
            <div className="modal-body">
                <h5>{this.props.alertBodyText}</h5>
            </div>
            <div className="expiry-modal-footer modal-footer">
            <div className="form-group row ">
                  <div className="col-md-12 px-0 text-right">
                      <button onClick={() => {this.closeModal()} } data-dismiss="modal" type="button" className="btn btn-default">
                        Cancel
                      </button>
                  </div>
            </div>
          </div>
        </div>

    </div>
    </div>

     )
 }
}

export default alertModal
