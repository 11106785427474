import React, {Component} from 'react'
import {
	BrowserRouter as Router,
	Redirect,
	Switch,
} from "react-router-dom";

class Shelves extends Component {

// Class constructor 
constructor(props){
	
	super(props);


	this.state = {
		error : null,
		palletData : [],

		childSetActive : "active",
		isShelveActive : "",
		hiddenClass : "hidden",

		tableActive : "",

		ColdroomList: [],
		currentColdroomName : "",
		currentColdroomId : "",

		factoryList : [],
		currentFactoryName : "",
		currentFactoryId : "",
	};

}

// Onclick function for sending pallet data to index.js
sendPalletId = (the_id) => {
	this.props.pallet_ID(the_id);
}

// Create a pallet to put on the table
create_pallet = (row, col, shelve_pos) => {

	var pallet_item;
	var pallets = this.props.pallets;
	
		pallets.forEach(

		function palletsFunction(item, index) {

			function palletClass(category){
				if(category === "Beef"){
					return category.toLowerCase();
				}else if(category === "Chicken"){
					return category.toLowerCase();
				}else if(category === "Pork"){
					return category.toLowerCase();
				}else if(category === "Lamb"){
					return category.toLowerCase();
				}
			}

			if(item.row_pos === row && item.column_pos === col && item.shelve_pos === shelve_pos && (item.is_indropzone !== 'true' && item.is_sold !== 'true'  && item.is_in_coldstorage !== 'true')){
				pallet_item = (<a key={item.id} onClick={() => {this.sendPalletId(item.id)}} href="#" className={"box "+palletClass(item.pallet_category)} data-toggle="modal" data-target="#paletteModal">
									{item.pallet_name}
								</a>); 
			}
			
		}.bind(this));

		return pallet_item;
}


// Create table TD
createColumns = (j,cols,shelve_pos) =>{
	var table_cols = [];
	for (let x = 1; x <= cols; x++) {
		table_cols.push(<td key={x} className={"item row_"+j} >
				{
					this.create_pallet(j,x, shelve_pos)
				}
			</td>
		);
				
	}
	return table_cols;
}

// Create table TD
createHeadings = (cols) =>{
	var table_cols = [];
	for (let x = 1; x <= cols; x++) {
		var th = "";
		if(x === 1){th = "A"
		}else if(x === 2){th = "B"
		}else if(x === 3){th = "C"
		}else if(x === 4){th = "D"
		}else if(x === 5){th = "E"
		}else if(x === 6){th = "F"
		}else if(x === 7){th = "G"
		}else if(x === 8){th = "H"
		}else if(x === 9){th = "I"
		}else if(x === 10){th = "J"
		}else if(x === 11){th = "K"
		}else if(x === 12){th = "L"
		}else if(x === 13){th = "M"
		}else if(x === 14){th = "N"
		}else if(x === 15){th = "O"
		}else if(x === 16){th = "P"
		}else if(x === 17){th = "Q"
		}else if(x === 18){th = "R"
		}else if(x === 19){th = "S"
		}else if(x === 20){th = "T"
		}else if(x === 21){th = "U"
		}else if(x === 22){th = "V"
		}else if(x === 23){th = "W"
		}else if(x === 24){th = "X"
		}else if(x === 25){th = "Y"
		}else if(x === 26){th = "Z"
		}

		table_cols.push(
			<td>
        {
          th
        }
      </td>
		);

  }	
  
  console.log(table_cols);
  
	return table_cols;
}


// Create a table for each shelve
createTable = (rows, cols, shelve, shelve_pos) => {
	
	var table_row = [];
	
	for (let j = rows; j >= 1; j--) {

		table_row.push(
		<tr key={j} className={"row_"+j}>
			<td className="text-center">{j}</td>
			{
				this.createColumns(j,cols,shelve_pos)
			}
			<td className="text-center">{j}</td>	
		</tr>)	
		
	}

	return table_row
}

// Create an active class for first item in MAP  
activeClass = (index, ActiveClass) => {
	if(index === 0){
		return ActiveClass;
	}else{
		return "";
	}
}

activeShelve = (index, ActiveClass) =>{
	// alert(index);

	console.log(index+" | "+ActiveClass)

	if(index === 0){
		return ActiveClass;
	}else{
		return "";
	}
}

hiddenClassFunc = (shelve) => {
	if(shelve == "floor" || shelve == "floor-bottom"){
		this.setState({
			isShelveActive: "no",
			childSetActive : "active",
			hiddenClass: "",
		});
	}else{
		this.setState({
			hiddenClass: "hidden",
			childSetActive : '',
			isShelveActive: "",
		});
	}
}

hideChildShelves = (is_child) => {

	if(is_child === "child"){
		return "hidden";
	}else{
		return "";
	}
	
}

// Onlclick event to change factory
changeFactory = (index, name, id, coldrooms) => {
	var roomName = "";
	var roomID = "";

	if(coldrooms.length !== 0) {
		roomName = coldrooms[0]['coldroom_name'];
		roomID = coldrooms[0]['coldroom_id'];
	}

	this.setState({
		currentFactoryName: name,
		currentFactoryId: id,
		ColdroomList : coldrooms,
		currentColdroomName : roomName,
		currentColdroomId : roomID,
		hiddenClass: "hidden",
		childSetActive : '',
		isShelveActive: "",
	});

	this.props.requestUrl(id, roomID);
	
	return this.props.currentFactoryName;
}

// Onlclick event to change coldroom
changeColdroom = (index, name, id) => {
	
	this.setState({
		currentColdroomName: name,
		currentColdroomId: id,
		hiddenClass: "hidden",
		childSetActive : '',
		isShelveActive: "",
	});

	this.props.requestUrl(this.props.currentFactoryId, id);
	return this.props.currentColdroomName;
}



// Render the HTML to browser
 render(){
	var shelves_html;
	// Check for errors before render



	if(this.props.error != null){

		shelves_html = (
		<div className="row justify-content-center shelves-error">
		  <h4>{this.props.error}</h4>
		</div>);
		
	}else{

		shelves_html = (
			<div className="row justify-content-center">
				 <div className="col-md-2 mb-3 side-section">
					 <h3 className="m-0">Shelve</h3>
					 <ul className="nav nav-pills flex-column" id="myTab" role="tablist">
						{ this.props.shelves.map((shelve, index) => 
						 <li onClick={() => {this.hiddenClassFunc(shelve.shelve_position)}} key={shelve.shelve_id} className={"nav-item "+shelve.shelve_position +" "+this.hideChildShelves(shelve.shelve_type)}>
							 <a className={"nav-link "+this.activeClass(index, "active")} id={shelve.shelve_position+"-tab"} data-toggle="tab" href={"#"+shelve.shelve_position} role="tab" aria-controls={shelve.shelve_position} aria-selected="false">{shelve.shelve_position} <i className="fa fa-chevron-right"></i></a>
						 </li>
					 	)}
					 </ul>

				 </div>
				 <div className="col-md-7 pl-0 main-section">
				 <div className="tab-content" id="myTabContent">
	

					{ this.props.shelves.map((shelve, index) => 	
							<div key={shelve.shelve_id} className={"tab-pane fade "+(index ? 0 : 'active show')} id={shelve.shelve_position} role="tabpanel" aria-labelledby={shelve.shelve_position+"-tab"}>
							<table className="palette-table-grid">
								<thead>
										<tr>
												<td className="text-center"></td>
												{this.createHeadings(shelve.shelve_columns)}
												<td className="text-center"></td>
										</tr>
								</thead>
								<tbody>

								{this.createTable(shelve.shelve_rows, shelve.shelve_columns, shelve, shelve.shelve_position)}
				
								</tbody>
								<tfoot>
										<tr>
											<td></td>
											{this.createHeadings(shelve.shelve_columns)}
											<td></td>
										</tr>
									</tfoot>
						</table>
						</div>
					)} 

				</div>
					 </div>

				 <div className="col-md-2 mb-3 aside-section">
					<div className={"floor-shelves-menu "+this.state.hiddenClass} >
						<h3 className="m-0">Rows</h3>
						<ul className="nav nav-pills flex-column" id="myTab" role="tablist">
							<li onClick={() => {this.hiddenClassFunc('floor')}} className="nav-item">
								<a className={"nav-link "+this.state.childSetActive}  data-toggle="tab" href="#floor" role="tab" aria-controls="floor" aria-selected="true"><i className="fa fa-chevron-left">  </i>Top</a>
							</li>
							<li onClick={() => {this.hiddenClassFunc('floor-bottom')}} className="nav-item">
								<a className={"nav-link "+this.state.isShelveActive}  data-toggle="tab" href="#floor-bottom" role="tab" aria-controls="floor-bottom" aria-selected="false"> <i className="fa fa-chevron-left"></i> Bottom</a>
							</li>
						</ul>
					</div>
				</div>
			 </div>
		);

	} //End HTML for shelves

	var currentFactory;
	var currentColdroom;

	if(this.state.currentFactoryName === ""){
		currentFactory = this.props.currentFactory;
	}else{
		currentFactory = this.state.currentFactoryName;
	}

	if(this.state.currentColdroomName === ""){
		currentColdroom = this.props.currentColdroom;
	}else{
		currentColdroom = this.state.currentColdroomName;
	}

	//Render rest of HTML
	return(
	
		 <div>
			 <div className="row my-3">
		
				 <div className="col-md-6 text-right">
								

						 <div className="dropdown">
								 <button className="btn btn-secondary dropdown-toggle col-5" type="button" id="dropdownFactories" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									 {currentFactory}
								 </button>
								 
								 <div className="dropdown-menu" aria-labelledby="dropdownFactories">
									{ this.props.factories.map((factory, index) => 
										<a key={factory.factory_id} onClick={() => {this.changeFactory(index, factory.factory_name, factory.factory_id, factory.coldrooms)}} className="dropdown-item" href="#">{factory.factory_name} </a>
									)}
								 </div>
							 </div>
				 </div>
				 <div className="col-md-6 text-left">
						 <div className="dropdown">
						 		
								 <button className="btn btn-secondary dropdown-toggle col-5" type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								 	{currentColdroom}
								 </button>
								 <div className="dropdown-menu" aria-labelledby="dropdownMenuButton2">									
									 { this.props.ColdroomList.map((coldroom, index) => 
										<a key={coldroom.coldroom_id} onClick={() => {this.changeColdroom(index, coldroom.coldroom_name, coldroom.coldroom_id)}} className="dropdown-item" href="#">{coldroom.coldroom_name} </a>
									)}
								 </div>
							 </div>
				 </div>
			 </div>


										
			{shelves_html}
			 
		 </div>
	 )	 
 }
}

export default Shelves
