import React, {Component} from 'react'
import {Link } from "react-router-dom";
import axios from 'axios';
import Cookies from 'js-cookie';


import * as appConst from '../../functions/Constants';
const apiUrl = appConst.API_URL;

class ResetForm extends Component {

  constructor(props){
    super(props);
    this.state = {

      updateMessage : '',

      new_password : '',
      new_password_2 : '',
      reset_status : '',
      reset_id : '',
      user_email : ''
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  resetPassRequest = () =>{

    const data = {
      'user_password': this.state.new_password,
      'user_email': this.props.user_email,
      'reset_id': this.props.reset_id,
    }
    
    const headers = {
      'Content-Type': 'application/json',
    }
    
    axios.put(apiUrl+'/user/user-reset-pass',data,{
      headers: headers
    })
    .then(res => {
      
      if(res.data.status === "error"){
        this.setState({ 
          updateMessage : res.data.msg,
          reset_status: '',
        });

      }else{

        this.setState({
          reset_status: 'success',
          updateMessage :'',
        });

      }
    
    })
    .catch(err => {
      this.setState({ 
        updateMessage: "There was a technical problem while reseting your password." 
      })
      
      console.log(err);
    });
  
  
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    if(this.state.new_password !== this.state.new_password_2)
    {

      this.setState({
        updateMessage :"The passwords you entered do not match.",
      });

    }else if(this.state.new_password === "" || this.state.new_password_2 === ""){
      
      this.setState({
        updateMessage :"All fields must be filled in.",
      });

    }else{
      this.resetPassRequest();
    }

    // alert(this.props.reset_id);
  
  }



  componentDidMount(){



  }

 render(){

    if(this.state.reset_status === 'success'){
      return(
        <div>
          <div className="form-group row px-0">
            <h3>Success!</h3>
            <p>Your password has been reset. Click the below button to login.</p>
          </div>
          <div className="form-group row px-0">
            <div className="col-md-12 px-0">
                <Link to="/login" className="btn btn-default">
                  Click to login
                </Link>
            </div>
          </div>
        </div>
      )
    }else{
      return(
            <div>
              <h3>Reset password</h3>
              <p>It's a good idea to use a strong password that you don't use elsewhere</p>
               
               
              { this.state.updateMessage && <div className="reset-error alert alert-danger alert-dismissible fade show"> { this.state.updateMessage } </div> }
               
                <form onSubmit={this.handleSubmit}>
                    <div className="form-group row px-0">
                      <div className="col-md-3 px-0"><label for="new_password" className="col-form-label text-md-right">New password</label></div>
                        <div className="col-md-9 pr-0">
                            <input type="password" id="new_password" className="form-control" name="new_password" placeholder="New password" onChange={this.handleChange} value={this.state.new_password} required autofocus />
                        </div>
                    </div>

                    <div className="form-group row px-0">
                      <div className="col-md-3 px-0"><label for="new_password_2" className="col-form-label text-md-right">Confirm</label></div>
                        <div className="col-md-9 pr-0">
                            <input type="password" id="new_password_2" className="form-control" name="new_password_2" placeholder="Confirm new password" onChange={this.handleChange} value={this.state.new_password_2} required/>
                        </div>
                    </div>

                    <div className="form-group row px-0">
                      <div className="col-md-12 px-0">
                          
                          <button type="submit" className="btn btn-default"> Reset password</button>

                          <Link to="/login" className="btn btn-link forgot-password pull-right pr-0">
                            Back to login
                          </Link>
                      </div>

                    </div>
                </form>
          </div>
      )
   }
 }
}

export default ResetForm



