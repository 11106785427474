import React, {Component} from 'react'
import axios from "axios";
import Moment from 'react-moment';
import {Link} from "react-router-dom";
import Cookies from 'js-cookie';
import { CSVLink, CSVDownload } from "react-csv";

import * as appConst from '../../functions/Constants';
const apiUrl = appConst.API_URL;

class ColdStorage extends Component {


	constructor(props){

		super(props);
		this.state = {
			error: null,
			actionMessage : '',
			isLoaded: false,
			storagePallets : [],
			palletId : '',
			viewDropzoneModal : '',
			factory_id : '',
			coldroom_id : '',
			factory_name : '',
			coldroom_name : '',
			pallet_id : '',
		};
	
	
	}
	
	
// HTTP request to get All users
getStoragePallets = () => {
	axios.get(apiUrl+'/pallets/storage-pallets', 
		{
			headers: {
				Authorization: Cookies.get('meatco_cookie'),
				'Content-Type': 'application/json'
			}
		})
	.then(res => {
		

		if(res.data.status === "error"){
			this.setState({ 
				actionMessage : res.data.msg,
			});
		  }else{
			this.setState({ 
				storagePallets : res.data,
			});
		  }
	
	})
	.catch(err => {
		this.setState({ 
		error: "There was a technical problem while fetching sold pallets.",
		actionMessage : "There was a technical problem while fetching sold pallets."
		});
		this.props.openAlertModal(this.state.actionMessage, "", "Error");
	});
}



// HTTP request to update cut-type
movePalletsDropzone = (update_pallet_id) => {

	const data = {
		'is_indropzone': 'true',
		'is_in_coldstorage': 'false',
	}
  
	const headers = {
	  Authorization: Cookies.get('meatco_cookie'),
	  'Content-Type': 'application/json',
	}
  
	axios.put(apiUrl+'/pallets/update/'+update_pallet_id,data,{
	  headers: headers
	})
	.then(res => {
	  
	  if(res.data.status === "error"){
		this.setState({ 
			actionMessage : res.data.msg,
		});
		this.props.openAlertModal(this.state.actionMessage, " ", "Error!");
	  }else{
		this.setState({ 
			viewDropzoneModal : 'show',
		});

	  }
  
	})
	.catch(err => {
	  this.setState({ 
		actionMessage: "There was a technical problem while updating pallete information.",
	  })
  
	  this.props.openAlertModal(this.state.actionMessage, "", "Error");
		  
	  console.log(err);

	  alert("dffg");

	});
  
}


closeViewModal = () =>{
	this.setState({
		viewDropzoneModal : '',
	}); 
}


updateDropZone= (update_pallet_id, factory_id, coldroom_id, factory_name, coldroom_name ) =>{
	this.setState({
		viewDropzoneModal : '',
		factory_id : factory_id,
		coldroom_id : coldroom_id,
		factory_name : factory_name,
		coldroom_name : coldroom_name,
		pallet_id : update_pallet_id,
	}); 

	this.movePalletsDropzone(update_pallet_id);
}
	
	
componentDidMount() {
	this.getStoragePallets();  
}
	

viewDropzoneHTML = () =>{
	return(        
		<div className={" modal fade "+this.state.viewDropzoneModal} id="confirmModal" tabIndex="-1" role="dialog" aria-labelledby="addNewUser" aria-hidden="true">
		<div className="modal-dialog modal-dialog-centered animated fadeIn" role="document">
			<div className="modal-content">
				<div className="modal-header">
					<span onClick={() => {this.closeViewModal()} } data-dismiss="modal" className="ai-close-icon"><img src="assets/img/close-icon.svg" alt="close"/></span>
					<h3 className="modal-title">Drop zone</h3>
				</div>
				<div className="modal-body">

						<h6>Palette {this.state.pallet_id} has been successfully dropped into {this.state.coldroom_name} ({this.state.factory_name}) Drop zone. </h6>
		

						<div className="form-group row confirmBtns">
							<div className="col-md-12 px-0 text-right">

								<Link className='ai-btn-edit btn btn-default' to={"/?factory_id="+this.state.factory_id+"&coldroom_id="+this.state.coldroom_id+"&pallete_id="+this.state.id}>View coldroom</Link>

								<button onClick={() => {this.closeViewModal()} } type="button" className="add-cut-type-btn btn btn-default">
									cancel
								</button>
							</div>
						</div>

				</div>			
			</div>
		</div>
		</div>
	)
}



 render(){
	var csvData = [
        ["","Factory Name", "Coldroom name", "Palette ID", "Expiry date"],
      ];

    this.state.storagePallets.map((pallete, index) => 
        csvData.push(
            [index+1, pallete.factory_name, pallete.coldroom_name, pallete.pallet_id, pallete.expiry_date],
        ),
	)

	 return(
     
		<div className="tab-pane" id="cold-storage" role="tabpanel" aria-labelledby="cold-storage-tab">
		<div className="row">
		  <div className="col-md-12">
			  <h3>Cold storage palettes</h3>
		  </div>
		</div>
		<div className="row">
		  <div className="col-md-12">
			<table className="table">
				<thead>
					<tr>
						<th>Factory name</th>
						<th>Coldroom name</th>
						<th>Palette ID</th>
						<th>Expiry date</th>
						<th></th>
					</tr>
				</thead>
				<tbody>

				{ this.state.storagePallets.map((pallete, index) => 
					<tr key={index} className={pallete.status}>
						<td>{pallete.factory_name}</td>
						<td>{pallete.coldroom_name}</td>
						<td>{pallete.pallet_id}</td>
						<td><Moment format="DD MMMM YYYY">{pallete.expiry_date}</Moment></td>
						<td><a href="#" className='ai-btn-edit' onClick={() => {this.updateDropZone(pallete.id, pallete.factory_id, pallete.coldroom_id, pallete.factory_name, pallete.coldroom_name)}} to={"/?factory_id="+pallete.factory_id+"&coldroom_id="+pallete.coldroom_id+"&pallete_id="+pallete.id}>Drop</a> </td>
					</tr>
				)}

				</tbody>
			</table>
		  </div>
		</div>

		<div className="row">
		  <div className="col-md-12">
            <CSVLink
                data={csvData}
                filename={"cold-storage.csv"}
                className="btn btn-default pull-right download-data"
                target="_blank"
            >
                <img src="/assets/img/download.svg"/> Download data
            </CSVLink>

		  </div>
		</div>

		{this.viewDropzoneHTML()}

	  </div>
      
	 )
 }
}

export default ColdStorage
