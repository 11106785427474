import React, {Component} from 'react'
import axios from "axios";
import { Redirect } from "react-router-dom";
import Cookies from 'js-cookie';

import * as appConst from '../../functions/Constants';
const apiUrl = appConst.API_URL;

class ProfileDetails extends Component {

// Class constructor 
constructor(props){

	super(props);
    this.state = {
      error: null,
      updateMessage : '',
      isLoaded: false,
      profileDetails : [],
      firstName : '',
      lastName : '',
      emailAddress : '',
      profType : '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }


// HTTP request to get logged in user
getUserDetails = () => {

  axios.get(apiUrl+'/user/get/'+this.props.user_id, 
	{
		headers: {
			Authorization: Cookies.get('meatco_cookie')
		}
	})
  .then(res => {
    
    this.setState({ 
      profileDetails : res.data,
      firstName : res.data[0].first_name,
      lastName : res.data[0].last_name,
      emailAddress : res.data[0].user_email,
      profType : res.data[0].user_type,
    });
    
  })
  .catch(err => {
    this.setState({ 
      error: "There was a technical problem while fetching your profile information." 
    })
  });
}

// HTTP request to update current user
updateUserDetails = () => {

  const data = {
      'first_name': this.state.firstName,
      'last_name': this.state.lastName,
      'user_email': this.state.emailAddress,
      'user_type': this.state.profType,
  }

  const headers = {
    Authorization: Cookies.get('meatco_cookie'),
    'Content-Type': 'application/json',
  }

  axios.put(apiUrl+'/user/update/'+Cookies.get('meatco_user'),data,{
    headers: headers
  })
  .then(res => {
    
    this.setState({ 
      updateMessage : res.data.msg,
    });

    if(res.data.status === "error"){
      this.props.openAlertModal(this.state.updateMessage, " ", "Error!");
    }else{
      this.props.openAlertModal(this.state.updateMessage, " ", "Success!");
    }

    
  })
  .catch(err => {
    this.setState({ 
      updateMessage: "There was a technical problem while updating your profile information." 
    })

    this.props.openAlertModal(this.state.updateMessage, "", "Error");

  });

}



handleChange(event) {
  this.setState({
    [event.target.name]: event.target.value
  });
}

handleSubmit(event) {
  event.preventDefault();
  this.updateUserDetails();
}



componentDidMount() {
    this.getUserDetails();  
}


render(){

  

	 return(
     
       <div className="tab-pane fade show active" id="profile-detail" role="tabpanel" aria-labelledby="profile-detail-tab">
                      <div className="row">
                        <div className="col-md-12">
                            <h3>Profile details</h3>
                        </div>
                    
                      </div>
                      <div className="row">
                          <div className="col-md-12">
                          <form onSubmit={this.handleSubmit}>
                              <div className="form-group row px-0 bmd-form-group">
                                <div className="col-md-3 px-0"><label htmlFor="firstName" className="col-form-label text-md-right">First name</label></div>
                                  <div className="col-md-9">
                                      <input type="text" id="firstName" className="form-control" name="firstName" placeholder="First name" onChange={this.handleChange} value={this.state.firstName} required="" autoFocus=""/>
                                  </div>
                              </div>
                              <div className="form-group row px-0 bmd-form-group">
                                  <div className="col-md-3 px-0"><label htmlFor="lastName" className="col-form-label text-md-right">Last name</label></div>
                                    <div className="col-md-9">
                                        <input type="text" id="lastName" className="form-control" name="lastName" placeholder="Last name" onChange={this.handleChange} value={this.state.lastName}  required="" autoFocus=""/>
                                    </div>
                                </div>
                                <div className="form-group row px-0 bmd-form-group">
                                    <div className="col-md-3 px-0"><label htmlFor="emailAddress" className="col-form-label text-md-right">Email</label></div>
                                      <div className="col-md-9">
                                          <input type="text" id="emailAddress" className="form-control" name="emailAddress" placeholder="Email" onChange={this.handleChange} value={this.state.emailAddress} required="" autoFocus=""/>
                                      </div>
                                  </div>
                                  <div className="form-group row px-0 no-margin-bottom">
                                      <div className="col-md-3 px-0"><label htmlFor="email_address" className="col-form-label text-md-right">Profile type</label></div>
                                        <div className="col-md-9">
                                              <div className="input-group mb-3">
                                                  <select className="form-control custom-select" id="profType" name="profType" onChange={this.handleChange}>
                                                    <option value={this.state.profType}>{this.state.profType}</option>
                                                    <option value="admin">Admin</option>
                                                    <option value="floor-worker">Floor Worker</option>
                                                  </select>
                                                </div>
                                        </div>
                                    </div>
                                    <div className="form-group row px-0 text-right">
                                      <div className="col-md-12">
                                          <button type="submit" className="btn btn-default pull-right" data-toggle="modal" data-target="#reminderpalettes">Update</button>
                                      </div>
                                    </div>
                          </form>
                          </div>
                      </div>
                    </div>
      
	 )
 }
}

export default ProfileDetails
