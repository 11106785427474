import React, {Component} from 'react'
import { Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import logo from '../assets/logo.svg';
import LoginForm from '../components/login/LoginForm';

import * as appConst from '../functions/Constants';
const apiUrl = appConst.API_URL;

class Login extends Component {
  constructor(props){
    super(props);

    // var handlelogin = this.handleLogin.bind(this);

    this.state = {
      loginView:true,
      redirect: null,
      forgotPasswordView:false,
      resetPasswordView:false,
    }
    

  }
  componentDidMount(someredirect){
    const cookieExists = Cookies.get('meatco_cookie');
  
    if(!cookieExists){
      this.setState({redirect: null});
    }else if(cookieExists){
      this.handleLogin();
    }
  }

  handleLogin(loginredirect){
    var loginredirect = "/home";
    this.setState({redirect:loginredirect})
     if(this.state.redirect){
      return <Redirect to={loginredirect} />
    }
  }

  

 render(){
  var handleLogin = this.handleLogin;

  const cookieExists = Cookies.get('meatco_cookie');
  const userExist = Cookies.get('meatco_user');
  if(cookieExists && userExist){
    return <Redirect to="/" />
  }else{
	 return(
         <div className="login-page">
            <div className="wrapper">
                <div className="content">
                <div className="content">
                <div className="container h-100">
                <div className="row align-items-center h-100">
                    <div className="col-md-5 mx-auto">
      
                <div className="card-avatar text-center">
                    <a href="#pablo">
                      <img src={logo} className="img"  alt="Meatco Logo"/>
                    </a>
                  </div>
                <div className="card">
                  <div className="card-body">
                 
                 
                <LoginForm handleLogin = {handleLogin.bind(this)} />
                  
                  
                  </div>
              </div>
          </div>
        </div>
        </div>
        </div>
    </div>
    </div>
    </div>
	 );}
 }
}


export default Login
