import React from 'react';

function Create404() {
  return (
    <div>
			<h1> Page not Found. </h1>
		</div>
  );
}

export default Create404;
