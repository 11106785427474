import React, {Component} from 'react'
import { Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import logo from '../assets/logo.svg';
import ResetPasswordForm from '../components/login/ResetForm';

import * as appConst from '../functions/Constants';
const apiUrl = appConst.API_URL;


class ResetPassword extends Component {
  constructor(props){
    super(props);
    this.state = {
      reset_id : '',
      user_email : '',
      isAllowed : '',
    }
  }


removeUrlParams = () =>{
    var myNewURL = refineURL();
    window.history.pushState("object or string", "Title", "/" + myNewURL );
  
    function refineURL()
    {
        var currURL= window.location.href;
        var afterDomain= currURL.substring(currURL.lastIndexOf('/') + 1);
        var beforeQueryString= afterDomain.split("?")[0];  
        return beforeQueryString;     
    }
}
  
componentWillMount(){
  
  // Search if there are any queries on the URL
  const params = new URLSearchParams(this.props.location.search);
  const query_reset_id = params.get('id');
  const query_user = params.get('user')

  if((query_reset_id === "" || query_reset_id === null) && (query_user === "" || query_user === null)){

    this.setState({
      isAllowed : 'no',
    });

  }else{

    this.setState({
      reset_id : query_reset_id,
      user_email : query_user
    });

  }

}


 render(){
  const cookieExists = Cookies.get('meatco_cookie');
  const userExist = Cookies.get('meatco_user');
  if(cookieExists && userExist){
    return <Redirect to="/" />
  }else if(this.state.isAllowed === "no"){
    return <Redirect to="/forgot-password" />
  }else{
	 return(
         <div className="login-page">
            <div className="wrapper">
                <div className="content">
                <div className="content">
                <div className="container h-100">
                <div className="row align-items-center h-100">
                    <div className="col-md-5 mx-auto">
      
                <div className="card-avatar text-center">
                    <a href="#pablo">
                      <img src={logo} className="img"  alt="Meatco Logo"/>
                    </a>
                  </div>
                <div className="card">
                  <div className="card-body">            
                 
                    <ResetPasswordForm 
                      reset_id= {this.state.reset_id} 
                      user_email= {this.state.user_email}
                    />
                  
                  </div>
              </div>
          </div>
        </div>
        </div>
        </div>
    </div>
    </div>
    </div>
	 )
 }
}
}

export default ResetPassword
