import React, {Component} from 'react'

class Dropzone extends Component {


sendPalletId = (the_id) => {
	this.props.pallet_ID(the_id);
}

create_pallet = () => {

	var pallet_item;
	var pallets = this.props.pallets;
	var table_cols = [];
	var cols_length;
	var other_td;

	pallets.forEach(
	function palletsFunction(item, index) {

		function palletClass(category){

			if(category === "Beef"){
				return "blue "+category;
			}else if(category === "Chicken"){
				return "orange "+category;
			}else if(category === "Pork"){
				return "purple "+category;
			}else if(category === "Lamb"){
				return "green "+category;
			}

		}

		if(item.is_indropzone === 'true' || item.is_indropzone === true && (item.is_sold !== 'true' && item.is_in_coldstorage !== 'true')){
			pallet_item = (<td key={item.id} className="item ">
								<a key={item.id} onClick={() => {this.sendPalletId(item.id)}} href="#" className={"box "+palletClass(item.pallet_category)} data-toggle="modal" data-target="#paletteModal">
									{item.pallet_name}
								</a>
							</td>);
			
			table_cols.push(pallet_item);
		}
	}.bind(this));

	cols_length = table_cols.length;

	other_td = 14 - table_cols.length;

	if(cols_length <= 14){

		for (let i = 0; i < other_td; i++) {
			table_cols.push(
				<td key={i} className="item ">
					<a className="box"></a>
				</td>
			);
		}
	}
	

	return table_cols;
}

 render(){

	if(this.props.error != null){
		return ("");
	}


	 return(
		 <div className="row justify-content-center">
		 	<div className="col-md-2 pr-0">
		 		<h3>Drop Zone</h3>
		 	</div>
		 	<div className="col-md-10 pl-0">
		 		<table className="palette-dropzone">
				<tbody>
		 				<tr>
		 					{
								this.create_pallet()
							}
		 				</tr>
						</tbody>
		 		</table>
		 	</div>
			</div>
	 )
 }
}

export default Dropzone
