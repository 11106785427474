import React, {Component} from 'react'
import axios from "axios";
import Moment from 'react-moment';
import {Link} from "react-router-dom";
import Cookies from 'js-cookie';
import { CSVLink, CSVDownload } from "react-csv";

import * as appConst from '../../functions/Constants';
const apiUrl = appConst.API_URL;

class Reminders extends Component {

constructor(props){

	super(props);
	this.state = {
		error: null,
		actionMessage : '',
		isLoaded: false,
		expiringPalettes : [],
	};


}


// HTTP request to get All users
getAllReminders = () => {
	axios.get(apiUrl+'/reminder/list', 
	  {
		  headers: {
			  Authorization: Cookies.get('meatco_cookie'),
			  'Content-Type': 'application/json'
		  }
	  })
	.then(res => {
	  this.setState({ 
		expiringPalettes : res.data,
	  });
   
	})
	.catch(err => {
	  this.setState({ 
		error: "There was a technical problem while reminders.",
		actionMessage : "There was a technical problem while fetching reminders."
	  });
	  this.props.openAlertModal(this.state.actionMessage, "", "Error");
	});
}




componentDidMount() {
    this.getAllReminders();  
}


 render(){
	
	var csvData = [
        ["","Factory Name", "Coldroom name", "Palette ID", "Expiry date"],
      ];

    this.state.expiringPalettes.map((pallete, index) => 
        csvData.push(
            [index+1, pallete.factory_name, pallete.coldroom_name, pallete.pallet_id, pallete.expiry_date],
        ),
	)

	 return(
     
		<div className="tab-pane" id="reminders" role="tabpanel" aria-labelledby="reminders-tab">
		<div className="row">
		  <div className="col-md-12">
			  <h3>Expiring palettes</h3>
		  </div>
		</div>
		<div className="row">
		  <div className="col-md-12">
			<table className="table">
				<thead>
					<tr>
						<th>Factory name</th>
						<th>Coldroom name</th>
						<th>Palette ID</th>
						<th>Expiry date</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					
				{ this.state.expiringPalettes.map((pallete, index) => 
					<tr key={index} className={pallete.status}>
						<td>{pallete.factory_name}</td>
						<td>{pallete.coldroom_name}</td>
						<td>{pallete.pallet_id}</td>
						<td><Moment format="DD MMMM YYYY">{pallete.expiry_date}</Moment></td>
						<td><Link className='ai-btn-edit' to={"/?factory_id="+pallete.factory_id+"&coldroom_id="+pallete.coldroom_id}>View</Link> </td>
					</tr>
				)}					
				</tbody>
			</table>
		  </div>
		</div>

		<div className="row">
		  <div className="col-md-12">
            <CSVLink
                data={csvData}
                filename={"reminders.csv"}
                className="btn btn-default pull-right download-data"
                target="_blank"
            >
                <img src="/assets/img/download.svg"/> Download data
            </CSVLink>;

		  </div>
		</div>

	  </div>
      
	 )
 }
}

export default Reminders
