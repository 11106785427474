import React, {Component} from 'react'
import axios from "axios";
import Moment from 'react-moment';
import {Link} from "react-router-dom";
import Cookies from 'js-cookie';

import * as appConst from '../../functions/Constants';
const apiUrl = appConst.API_URL;

class Factories extends Component {



	constructor(props){

		super(props);
		this.state = {
			error: null,
			actionMessage : '',
			isLoaded: false,
			addFactoryModalOpen :'',
			alertModalOpen :'',
			  
			edit_factory_id : '',
			edit_factory_name : '',
			modal_title : '',
			ButtonText : '',
			submitAction : '',
			confirmDeleteModal : '',

			input_FactoryName : '',

			allFcatories : [],
		};
		
		this.handleChange = this.handleChange.bind(this);
    	this.handleSubmit = this.handleSubmit.bind(this);
	
	}

	
// HTTP request to get All users
getFactories = () => {
	axios.get(apiUrl+'/factories/list', 
		{
			headers: {
				Authorization: Cookies.get('meatco_cookie'),
				'Content-Type': 'application/json'
			}
		})
	.then(res => {
		this.setState({ 
			allFcatories : res.data,
		});
	
	})
	.catch(err => {
		this.setState({ 
			error: "There was a technical problem while fetching sold pallets.",
			actionMessage : "There was a technical problem while fetching sold pallets."
		});
		this.props.openAlertModal(this.state.actionMessage, "", "Error");
	});
}

// HTTP request to update factory
updateFactoryDetails = (factory_id) => {

	const data = {
		'factory_name': this.state.input_FactoryName,
	}
  
	const headers = {
	  Authorization: Cookies.get('meatco_cookie'),
	  'Content-Type': 'application/json',
	}
  
	axios.put(apiUrl+'/factories/update/'+this.state.edit_factory_id,data,{
	  headers: headers
	})
	.then(res => {
	  
	  if(res.data.status === "error"){
		this.setState({ 
			actionMessage : res.data.msg,
		});
		this.props.openAlertModal(this.state.actionMessage, " ", "Error!");
	  }else{
		this.setState({ 
			actionMessage : res.data.msg,
			addFactoryModalOpen : " ",
			confirmDeleteModal : '',
		});

		this.getFactories(); 

		this.props.openAlertModal(this.state.actionMessage, " ", "Success!");
	  }
  
	})
	.catch(err => {
	  this.setState({ 
		actionMessage: "There was a technical problem while updating factory information.",
		addFactoryModalOpen : '' 
	  })

	  console.log(err)
  
	  this.props.openAlertModal(this.state.actionMessage, "", "Error");
  
	});
  
}

// HTTP request to delete factory
deleteFactory = (factory_id) => {
	axios.delete(apiUrl+'/factories/delete/'+factory_id, 
	  {
		  headers: {
			  Authorization: Cookies.get('meatco_cookie')
		  }
	  })
	.then(res => {
	  
	  this.setState({ 
		actionMessage : res.data.msg,
		addFactoryModalOpen : "",
	  });

	  if(res.data.status === "error"){
		this.setState({ 
			actionMessage : res.data.msg,
		});
		this.props.openAlertModal(this.state.actionMessage, " ", "Error!");
	  }else{
		this.setState({ 
			actionMessage : res.data.msg,
			addFactoryModalOpen : " ",
			confirmDeleteModal : '',
		});

		this.getFactories(); 

		this.props.openAlertModal(this.state.actionMessage, " ", "Success!");
	  }

	})
	.catch(err => {
	  this.setState({ 
		error: "There was a technical problem while deleting the factory.",
		actionMessage : "There was a technical problem while deleting the factory.",
		addFactoryModalOpen : '' 
	  });

	  this.props.openAlertModal(this.state.actionMessage, " ", "Success!");

	});
}


// HTTP request to get factory
getSingleFactory = (factory_id) => {

	axios.get(apiUrl+'/factories/get/'+factory_id, 
	  {
		  headers: {
			  Authorization: Cookies.get('meatco_cookie')
		  }
	  })
	.then(res => {
	  
	  this.setState({ 
		input_FactoryName : res.data[0].factory_name,
	  });


	})
	.catch(err => {
	  this.setState({ 
		error: "There was a technical problem while fetching the factory.",
		actionMessage : "There was a technical problem while fetching the factory.",
		addFactoryModalOpen : '' 
	  });

	  this.props.openAlertModal(this.state.actionMessage, "", "Error");

	});
}


// HTTP request to add new factory
CreateNewFactory = () => {

	const data = {
		'factory_name': this.state.input_FactoryName,
	}
  
	const headers = {
	  Authorization : Cookies.get('meatco_cookie'),
	  'Content-Type': 'application/json',
	  Accept : 'application/json'
	}
  
	axios.post(apiUrl+'/factories/create',data,{
	  headers: headers
	})
	.then(res => {
	  
	  
	  this.getFactories();

	  if(res.data.status === "error"){
		this.setState({ 
			actionMessage : res.data.msg,
		});
		this.props.openAlertModal(this.state.actionMessage, " ", "Error!");
	  }else{
		this.setState({ 
			actionMessage : res.data.msg,
			addFactoryModalOpen : " ",
		});

		this.props.openAlertModal(this.state.actionMessage, " ", "Success!");
	  }
	  
	})
	.catch(err => {
	  this.setState({ 
		actionMessage: "There was a technical problem while creating the factory.",
		addFactoryModalOpen : '' 
	  });
	  this.props.openAlertModal(this.state.actionMessage, " ", "Success!");
	});
}



handleChange(event) {
	this.setState({
		[event.target.name]: event.target.value
	});
}
	
handleSubmit(event) {
	event.preventDefault();	
	
	if(this.state.submitAction === "add"){
	
		if( this.state.input_FactoryName === "")
		{
			this.props.openAlertModal("All fields must be filled in.", " ", "Error!");
	
		}else{
			this.CreateNewFactory();
			this.getFactories()
		}
		
	}
	
	if(this.state.submitAction === "edit"){
		if( this.state.input_FactoryName === "")
		{
			this.props.openAlertModal("All fields must be filled in.", " ", "Error!");
	
		}else{
			this.updateFactoryDetails();
			this.getFactories();
		}
	}
	
}


closeModal = () =>{
	this.setState({
		addFactoryModalOpen : "",
		modal_title : '',
		edit_factory_id : '',
		edit_factory_name : '',
		ButtonText : '',
		submitAction : '',
		confirmDeleteModal : '',
	}); 
}

closeDelete = () =>{
	this.setState({
		confirmDeleteModal : '',
	}); 
}

addNewModal = (title) =>{
	this.setState({
		addFactoryModalOpen : "show",
		modal_title : title,
		ButtonText : 'Add factory',
		submitAction : 'add',
	});
}

editModal = (title, factory_id, factory_name) =>{
	this.setState({
		addFactoryModalOpen : "show",
		edit_factory_id : factory_id,
		edit_factory_name : factory_name,
		modal_title : title,
		ButtonText : 'Update factory',
		submitAction : 'edit',
	});

	this.getSingleFactory(factory_id);
}


deleteUserModal = () =>{
	this.deleteFactory(this.state.edit_factory_id);
	this.getFactories();  
}

deleteBtn= () =>{
	this.setState({
		confirmDeleteModal : 'show',
	});
}	

componentDidMount() {
	this.getFactories();  
}
	

newFactoryModalHTML = () =>{
	return(        
		<div className={this.state.submitAction+" modal fade "+this.state.addFactoryModalOpen} id="alertModal" tabIndex="-1" role="dialog" aria-labelledby="addNewUser" aria-hidden="true">
		<div className="modal-dialog modal-dialog-centered animated fadeIn" role="document">
			<div className="modal-content">
				<div className="modal-header">
					<span onClick={() => {this.closeModal()} } data-dismiss="modal" className="ai-close-icon"><img src="assets/img/close-icon.svg" alt="close"/></span>
					<h3 className="modal-title">{this.state.modal_title}</h3>
				</div>
				<div className="modal-body">
					
					<form onSubmit={this.handleSubmit}>
						<div className="row">
							<div className="col-md-12">
								
								<div className="form-group row px-0">
									<div className="col-md-3 px-0">
									<label htmlFor="input_FactoryName" className="col-form-label text-md-right">Factory Name</label></div>
									<div className="col-md-9 pr-0">
										<input type="text" id="input_FactoryName" className="form-control" name="input_FactoryName" placeholder=" " value={this.state.input_FactoryName || ''} required onChange={this.handleChange} />
									</div>
								</div>
								
							</div>
						</div>
						
		
						<div className="form-group row ">
							<div className="col-md-12 px-0 text-right">

								<button type="button" onClick={() => {this.deleteBtn()} }  className="delete-btn delete-factory-btn btn btn-default">
									Delete
								</button>

								<button type="submit" className="add-factory-btn btn btn-default">
									{this.state.ButtonText}
								</button>
							</div>
						</div>

					</form>
				</div>			
			</div>
		</div>
		</div>
	)
}


confirmDeleteHTML = () =>{
	return(        
		<div className={" modal fade "+this.state.confirmDeleteModal} id="confirmModal" tabIndex="-1" role="dialog" aria-labelledby="addNewUser" aria-hidden="true">
		<div className="modal-dialog modal-dialog-centered animated fadeIn" role="document">
			<div className="modal-content">
				<div className="modal-header">
					<span onClick={() => {this.closeDelete()} } data-dismiss="modal" className="ai-close-icon"><img src="assets/img/close-icon.svg" alt="close"/></span>
					<h3 className="modal-title">Delete factory</h3>
				</div>
				<div className="modal-body">

						<h6>Are you sure you want to delete "{this.state.edit_factory_name}" ?</h6>
						<p>This factory will be deleted immediately. You can’t undo this action.</p>		

						<div className="form-group row confirmBtns">
							<div className="col-md-12 px-0 text-right">

								<button type="button" onClick={() => {this.deleteUserModal()} }  className="delete-btn delete-factory-btn btn btn-default">
									DELETE
								</button>

								<button onClick={() => {this.closeDelete()} } type="button" className="add-factory-btn btn btn-default">
									cancel
								</button>
							</div>
						</div>

				</div>			
			</div>
		</div>
		</div>
	)
}
	



 render(){
	 return(
     
		<div className="tab-pane" id="factories" role="tabpanel" aria-labelledby="factories-tab">
		<div className="row">
		  <div className="col-md-12">
			  <h3>Factories</h3>
		  </div>
		</div>
		<div className="row">
		  <div className="col-md-12">
			<table className="table">
				<thead>
					<tr>
						<th></th>
						<th></th>

					</tr>
				</thead>
				<tbody>

				{ this.state.allFcatories.map((factory, index) => 
					<tr key={index}>
						<td>{factory.factory_name}</td>
						<td className="td-button-col"><a href="#" onClick={() => {this.editModal("Edit Factory", factory.factory_id, factory.factory_name)} } className="ai-btn-edit">Edit</a></td>
					</tr>
				)}
					
				</tbody>
			</table>
		  </div>
		</div>
		
		<div className="row">
		  <div className="col-md-12">
			<a href="#" onClick={() => {this.addNewModal("Add Factory")} } className="btn btn-default pull-right">Add new factory</a>
		  </div>
		</div>
		
		{this.newFactoryModalHTML()}

		{this.confirmDeleteHTML()}


	  </div>
      
	 )
 }
}

export default Factories
