import React from 'react'

import {
	BrowserRouter as Router,
	Route,
	Switch,
	
} from "react-router-dom";

import Index from './pages/index';
import Profile from './pages/profile';
import NotFound from './pages/NotFound';
import Login from './pages/login';
import ResetPassword from './pages/resetPassword';
import ForgotPassword from './pages/forgotPassword';

function App() {
  return <Router>
		<Switch>
				<Route exact path="/" component={Index}/>
				<Route exact path="/index" component={Index}/>
				<Route exact path="/home" component={Index}/>
				<Route exact path="/dashboard" component={Index}/>
				<Route exact path="/profile" component={Profile}/>
				<Route exact from="/login" component={Login}/>
				<Route exact from="/reset-password" component={ResetPassword}/>	
				<Route exact from="/forgot-password" component={ForgotPassword}/>	
				<Route component={NotFound}/>
		</Switch>
	</Router>
}

export default App;
