import React, {Component} from 'react';
import Moment from 'react-moment';
// import 'moment-timezone';

import * as appConst from '../../functions/Constants';
const apiUrl = appConst.API_URL;

class PalletModal extends Component {
 render(){

  let image_url = "";


  if(this.props.pallet.image_url == "" || this.props.pallet.image_url == null || this.props.pallet.image_url == "null"){
    image_url = "assets/img/grey-camera.svg";
  }else{
    image_url = apiUrl+"/public/images/"+this.props.pallet.image_url;
  }

	 return(
		 <div className="pallet-modal modal-dialog modal-dialog-centered" role="document">
       <div className="modal-content">

         <div className="modal-body">
         <a href="" data-dismiss="modal" className="pallet-close ai-close-icon"><img src="assets/img/close-icon.svg" alt="close"/></a>
           <form action="">
               <div className="row">
                 <div className="col-md-6">
                   <h4 className="modal-title" id="exampleModalLabel">{this.props.pallet.pallet_name}</h4>
                     <div className="form-group row px-0">
                         <div className="col-md-3 px-0">
                           <label htmlFor="email_address" className="col-form-label text-md-right">Palette name</label></div>
                           <div className="col-md-9 pr-0">
                               <input type="text" id="palette_name" className="form-control" name="palette-name" placeholder="Palette name" disabled value={this.props.pallet.pallet_name} required />
                           </div>
                       </div>
                       <div className="form-group row px-0">
                           <div className="col-md-3 px-0">
                             <label htmlFor="email_address" className="col-form-label text-md-right">Palette ID</label>
                           </div>
                           <div className="col-md-9 pr-0">
                               <input type="text" id="palette_id" className="form-control" name="palette-id" placeholder="Palette ID" disabled value={this.props.pallet.pallet_id} required />
                           </div>
                       </div>
                       <div className="form-group row px-0">
                         <div className="col-md-3 px-0">
                           <label htmlFor="email_address" className="col-form-label text-md-right">Category</label>
                         </div>
                         <div className={"col-md-9 pr-0 pallet_cat "+this.props.pallet.pallet_category}>
                              <label className="radio-inline blue beef">
                                Beef
                              </label>
                              <label className="radio-inline purple pork">
                                Pork
                              </label>
                              <label className="radio-inline green lamb">
                                Lamb
                              </label>
                              <label className="radio-inline orange chicken">
                                Chicken
                              </label>
                              <label class="radio-inline orange ice">
                                Ice
                              </label>
                              <label class="radio-inline orange misc">
                                Misc
                              </label>
                         </div>
                     </div>
                       <div className="form-group row px-0">
                           <div className="col-md-3 px-0">
                             <label htmlFor="email_address" className="col-form-label text-md-right">Cut type</label>
                           </div>
                           <div className="col-md-9 pr-0"> 
                            <input type="text" id="palette_cut_type" className="form-control" name="palette-id" placeholder="Cut type" disabled value={this.props.pallet.cut_type_id} required />     
                          </div>
                       </div>

                       <div className="form-group row px-0">
                           <div className="col-md-3 px-0">
                             <label htmlFor="email_address" className="col-form-label text-md-right">Quantity</label>
                           </div>
                           <div className="col-md-9 pr-0"> 
                            <input type="text" id="palette_cut_type" className="form-control" name="palette-id" placeholder="Quantity" disabled value={this.props.pallet.quantity} required />     
                          </div>
                       </div>
                      
                       <div className="form-group row px-0">
                           <div className="col-md-3 px-0">
                             <label htmlFor="palette_quantity" className="col-form-label text-md-right">Timestamp</label>
                           </div>
                           <div className="col-md-9 pr-0">
                               <p className="col-form-label"><Moment format="DD MMMM YYYY &nbsp;&nbsp; H:mm">{this.props.pallet.created_at}</Moment></p>
                             </div>
                       </div>
                 </div>
                 <div className="col-md-6">
                     <div className="form-group row px-0">
                           <div className="card text-center my-0">
                             <div className="card-body">
                                 <img className="pallete-image" src={image_url} alt=""></img>
                                 <input type="file" className="form-control-file" id="exampleFormControlFile1"/>
                             </div>
                         </div>
                     </div>
                     <div className="form-group row px-0">
                         <div className="col-md-3 px-0">
                           <label htmlFor="palette_net_weight" className="col-form-label text-md-right">Net weight</label></div>
                           <div className="col-md-9 pr-0 input-group">
                               <input type="text" id="palette_net_weight" className="form-control" name="palette-net-weight" placeholder="Net weight" disabled value={this.props.pallet.net_weight}  aria-describedby="basic-addon1" required />
                               <div className="input-group-append">
                                   <span className="input-group-text" id="basic-addon1">kg</span>
                                 </div>
                           </div>
                       </div>
                       <div className="form-group row px-0">
                           <div className="col-md-3 px-0">
                             <label htmlFor="palette_expiry_date" className="col-form-label text-md-right">Expiry date</label></div>
                             <div className="col-md-9 pr-0">
                               <p className="col-form-label"><Moment format="DD MMMM YYYY">{this.props.pallet.expiry_date}</Moment></p>
                             </div>
                         </div>
                    
                 </div>
               </div>
           </form>
         </div>
          <div className="pallet-modal-footer modal-footer">
            <div className="form-group row ">
                  <div className="col-md-12 text-right">
                      <button data-dismiss="modal" type="button" className="btn btn-default">
                        Close
                      </button>
                  </div>
            </div>
          </div>

       </div>
     </div>
	 )
 }
}

export default PalletModal
