import React, {Component} from 'react'
import axios from "axios";
import Moment from 'react-moment';
import {Link} from "react-router-dom";
import Cookies from 'js-cookie';
import { CSVLink, CSVDownload } from "react-csv";

import * as appConst from '../../functions/Constants';
const apiUrl = appConst.API_URL;

class TotalCounts extends Component {



	constructor(props){

		super(props);
		this.state = {
			error: null,
			actionMessage : '',
			isLoaded: false,

			addCutTypeModalOpen :'',
			alertModalOpen :'',
			  
			edit_cuttype_id : '',
			edit_cuttype_name : '',
			modal_title : '',
			ButtonText : '',
			submitAction : '',
			confirmDeleteModal : '',

			input_CutTypeName : '',
			input_CategoryId : '',

			allCutTypesTotals : [],
			allCategories : [],
		};
		
		// this.handleChange = this.handleChange.bind(this);
		// this.handleSubmit = this.handleSubmit.bind(this);
		this.handleCategoryChange = this.handleCategoryChange.bind(this);
	
	}

	
// HTTP request to get All Coldrooms
getCutTypes = () => {


    let data = {
		'cut_type_name': this.state.input_CategoryId,
	}
  
	let headers = {
	  Authorization: Cookies.get('meatco_cookie'),
	}
  
	axios.post(apiUrl+'/cut-type/list-factory-totals',data,{
        headers: {
            Authorization: Cookies.get('meatco_cookie'),
        }
    })
	.then(res => {

		if(res.data.status === "error"){
			this.setState({ 
				actionMessage : res.data.msg,
				allCutTypesTotals : [],
			});
			alert(this.state.actionMessage);
		}else{
			this.setState({ 
				allCutTypesTotals : res.data,
			});

            console.log(res.data);
		}

	
	})
	.catch(err => {
		this.setState({ 
			error: "There was a technical problem while fetching totals.",
			actionMessage : "err.data.msg"
        });

        console.log(err.data);

		alert(this.state.actionMessage);
    });
    
    // alert(this.state.input_CategoryId)

}

// HTTP request to get All categories
getCategories = () => {

	// alert(this.state.input_CategoryId);

	axios.get(apiUrl+'/cut-type/list', 
		{
			headers: {
				Authorization: Cookies.get('meatco_cookie'),
				'Content-Type': 'application/json'
			}
		})
	.then(res => {

		if(res.data.status === "error"){
			this.setState({ 
				actionMessage : res.data.msg,
				allCategories : res.data,
			});
			this.props.openAlertModal(this.state.actionMessage, " ", "Error!");
		  }else{
			this.setState({ 
				allCategories : res.data,
			});

			if(this.state.input_CategoryId === ""){
				this.setState({ 
					input_CategoryId : res.data[0].category_name
				});
			}

			// alert(this.state.input_CategoryId)

			this.getCutTypes();

		  }
	
	})
	.catch(err => {
		this.setState({ 
			error: "There was a technical problem while fetching categories.",
			actionMessage : "There was a technical problem while fetching categories."
		});
		alert(this.state.actionMessage);

		console.log(err);
		
	});
}



handleCategoryChange(event) {
	this.setState({
		[event.target.name]: event.target.value
	});
	this.getCategories();
}


componentDidMount() {
	this.setState({
		input_CategoryId:"all"
	});
	this.getCategories();
    this.getCutTypes();
}
	



 render(){
    var categories = []; 
    
    var csvData = [
        ["","Factory Name", "Total Palletes", "Sold Palletes", "Total Weight"],
      ];

    this.state.allCutTypesTotals.map((CutType, index) => 
        csvData.push(
            ["1",CutType.factory_name, CutType.total_pallets, CutType.total_sold, CutType.total_weight+"KG"],
            ["2","Cold Storage",CutType.total_storage,"0", CutType.total_storage_kg+"KG"]
        ),


	)
	
	categories.push({
		value: "all",
		label: "All"
	})

	{ this.state.allCategories.map((category, index) => 
		// <option key={index} value={category.category_id}>{category.category_name}</option>
		categories.push({
			value: category.cut_type_name,
            label: category.cut_type_name
    
		})
	)}


	 return(
     
		<div className="tab-pane" id="total-count" role="tabpanel" aria-labelledby="total-count-tab">
		<div className="row">
		  <div className="col-md-12">
			  <h3>Total Counts</h3>
		  </div>
		</div>

		<div className="row">
			<div className="col-md-12">
				
				<div className="form-group row px-0">
					<div className="col-md-3 px-0">
					<label htmlFor="input_CategoryId" className="col-form-label text-md-right">Select a cut type</label></div>
					<div className="col-md-9 pr-0">

						<select className="form-control custom-select" id="input_CategoryId" name="input_CategoryId" required onChange={this.handleCategoryChange}>
							
							{ categories.map((category, index) => 
								<option key={index} value={category.value}>{category.label}</option>
							)}

						</select>

					</div>
				</div>
				
			</div>
		</div>

		<div className="row">
		  <div className="col-md-12">
			<table className="table tableAlign-left ">
                <thead>
                    <tr>
                        <th>Factory name</th>
                        <th>Total palettes</th>
                        <th>Sold palettes</th>
                        <th>Total weight</th>
                    </tr>
                </thead>
				
					
				{ this.state.allCutTypesTotals.map((CutType, index) => 

                    <tbody key={index}>
                        <tr>
                            <td>{CutType.factory_name}</td>
                            <td>{CutType.total_pallets}</td>
                            <td>{CutType.total_sold}</td>
                            <td class="not-button-col">{CutType.total_weight}KG</td>
                        </tr>
                        <tr>
                            <td>Cold Storage</td>
                            <td>{CutType.total_storage}</td>
                            <td>0</td>
                            <td class="not-button-col">{CutType.total_storage_kg}KG</td>
                        </tr>
                    </tbody>
                   

				)}

				
			</table>
		  </div>
		</div>
		
		<div className="row">
		  <div className="col-md-12">
            <CSVLink
                data={csvData}
                filename={"Totals.csv"}
                className="btn btn-default pull-right download-data"
                target="_blank"
            >
                <img src="/assets/img/download.svg"/> Download data
            </CSVLink>

		  </div>
		</div>

	  </div>
      
	 )
 }
}

export default TotalCounts
